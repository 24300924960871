$eFooterTextColor: $grayDark;
$eFooterHeadingColor: $grayDark;
$eFooterLinkColor: $eFooterTextColor;
$eFooterBackground: $brandColorSecondary;
$eFooterBtnTextColor: lighten($eFooterTextColor, 10%);
$eFooterBtnBackground: darken($eFooterBackground, 5%);
$eFooterBtnBackgroundHighlight: darken($eFooterBackground, 10%);
.e-footer {
    font-size: $baseFontSize * 0.846;
    line-height: $baseLineHeight;
    color: $eFooterTextColor;
    padding-top: $baseLineHeight * 1.5;
    padding-bottom: $baseLineHeight * 1.75;
    border-top: none;
    background: lighten($grayLight, 15%);
    @include border-radius(0);
    margin-top: $baseLineHeight * 2;
    // GENERAL
    > .span12 {
        margin-bottom: 0;
    }
    small {
        color: $gray;
    }
    a {
        text-decoration: none;
        color: $grayDark;
    }
    h3,
    h5 {
        color: $eFooterHeadingColor;
    }
    h3 {
        font-size: $baseFontSize + 21;
        margin-top: 0;
        margin-bottom: 0.8em;
    }
    h5 {
        color: $brandColorSecondary;
        font-family: $sansFontFamily;
        font-weight: $eFontWeightBold;
        font-size: $baseFontSize + 1;
        margin: 0;
    }
    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
    }
    img {
        max-width: 60%;
    }
    // BLOCKS
    @at-root #{&}-workshop-info {
        h5 {
            margin-bottom: 15px;
        }
    }
    @at-root #{&}-footer-social {
        ul {
            display: block;
            li {
                position: relative;
                display: block;
                float: right;
                margin-right: 0;
                margin-bottom: 0;
                text-align: right;
                width: 11%;
                margin-left: 4%;
                opacity: 0.5;
                // MOBILE
                @media (max-width: 767px) {
                    float: none;
                    display: inline-block;
                    margin-left: 2%;
                    margin-right: 2%;
                }
                &:hover {
                    @include transition(opacity, 0.25s);
                    opacity: 1;
                }
                img,
                svg {
                    width: 100%;
                    max-height: 2.3em;
                    @media (max-width: 767px) {
                        max-height: 4em;
                    }
                }
            }
        }
    }
    @at-root #{&}-footer-menu {
        font-size: 11px;
        ul {
            margin: 1.75em 0 2.25em 0;
            li {
                line-height: 14px;
            }
        }
        a {
            color: $grayDark;
            &:hover {
                color: $gray;
            }
        }
    }
    // MOBILE MODIFIERS
    @media (max-width: 767px) {
        padding-bottom: 100px;
        .span4 {
            text-align: center;
        }
        .span12.text-right {
            text-align: left;
        }
    }
    // FOOTER CONTACT CENTER
    .footer-contact-center {
        text-align: center;
        h5 {
            font-size: $baseFontSize + 4;
            color: $success;
            text-transform: uppercase;
        }
        .footer-mail {
            display: block;
            font-weight: $eFontWeightBold;
            font-size: $baseFontSize + 2;
            @media (max-width: 767px) {
                font-size: $baseFontSize;
            }
            padding: 10px 0 18px 0;
        }
        // CALL US BUTTON
        .btn {
            background: $success;
            border-bottom: 0.3em solid darken($success, 5%);
            color: $white;
            font-size: $baseFontSize;
            display: block;
            i {
                color: $white;
            }
            &:hover,
            &:focus,
            &:active {
                background: darken($success, 5%);
                border-bottom: 0.3em solid darken($success, 10%);
                i {
                    color: $white;
                }
            }
        }
    }
    //CONTACT INFO MODAL
    .master-footer-contact-modal {
        .modal-body {
            max-height: 90vh;
        }
        @media (min-width: 768px) {
            top: 10%;
            width: 500px;
            margin-left: -250px;
            .modal-body {
                max-height: calc(100vh - 160px);
                overflow-y: auto;
            }
        }
        @include border-radius(0px);
        @include box-sizing(border-box);
        border: 3px solid $brandColorSecondary;
        color: $textColor;
        @media (min-width: 768px) {
            .span6 {
                padding: 0 1.5em;
            }
        }
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            color: $headingsColor;
        }
        h3 {
            margin-bottom: 0.25em;
        }
        h3 span {
            display: block;
            font-weight: $eFontWeightNormal;
            font-size: .6em;
            margin-bottom: -10px;
            opacity: 0.8;
        }
        p {
            line-height: $baseLineHeight - 4;
        }
        table tr td:last-of-type {
            text-align: right;
        }
        .contact-number {
            font-size: 3em;
            @media (max-width: 767px) {
                font-size: 2em;
            }
            letter-spacing: -0.03em;
            font-weight: $eFontWeightBold;
            font-family: $masterSecondaryFontFamily;
            margin: 0.5em 0 0.75em 0;
            i,
            a {
                color: $gray;
            }
        }
        .master-button--large {
            background: $success;
            color: $white;
        }
    }
}

.master-footer-selected-workshop-bar {
    display: none;
    position: fixed;
    box-sizing: border-box;
    bottom: 0;
    background: $brandColorLight;
    width: 100%;
    font-size: .75em;
    color: white;
    z-index: 999;
    @media (max-width: 767px) {
        display: flex;
        justify-content: flex-start;
        flex-flow: row wrap;
    }
    div {
        padding: .3em;
    }
    .master-footer-selected-workshop-bar-name {
        margin-left: 10px;
    }
    .master-footer-selected-workshop-bar-clear {
        background: lighten($brandColorLight, 3%);
        margin-left: auto;
        border-left: 1px solid lighten($brandColorLight, 10%);
        a {
            padding: 0 1em;
            color: $error;
        }
    }
    .master-footer-selected-workshop-bar-switch {
        background: lighten($brandColorLight, 3%);
        border-left: 1px solid lighten($brandColorLight, 10%);
        border-right: 1px solid lighten($brandColorLight, 10%);
        a {
            padding: 0 1em;
            color: $white;
        }
    }
    .master-footer-selected-workshop-bar-pickup {
        width: 100%;
        padding: 0 16px;
        font-size: 1.1em;
        border-bottom: 1px solid $gray;
        small {
            color: #f4f4f3;
        }
    }
}
