$base: ".master-service-bookservice";
$baseModal: ".master-booking-service-details";
$gold: linear-gradient(135deg, #cb9b50 0%, #f7e9c6 50%, #e8d6a0 100%);
$silver: linear-master-service-bookservice-toggle-appendgradient(135deg, #e0e0e0 0%, #fefefe 50%, #f2f2f2 100%);
$bronze: linear-gradient(135deg, #d8ac9d 0%, #eacaba 50%, #e2bdad 100%);
#{$base} {
    #{$base}--hoverevent {
        &:hover {
            @include transition(all, 0.25s);
            border: 1px solid darken($grayLighter, 4%);
            @include box-shadow(5px 5px 0px lighten($grayLighter, 3%));
        }
    }
    #{$base}-form-reserved {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: rgba($white, .8);
        @include flexbox();
        @include flex-direction(column);
        @include justify-content(flex-start);
        @include align-items(center);
        z-index: 9;
        #{$base}-form-reserved-inner {
            text-align: center;
            background-color: $white;
            @include box-sizing(border-box);
            padding: 2.5em;
            @include box-shadow(5px 5px 0px rgba(0, 0, 0, 0.3));
            border: 1px solid $baseBorderColor;
            h2 {
                font-family: $masterSecondaryFontFamily;
                font-size: 2em;
                font-weight: $eFontWeightLight;
                line-height: 1;
            }
            .btn {
                background: $brandColor;
                border-bottom-color: darken($brandColor, 5%);
                color: $white;
                &.btn-primary {
                    background: $success;
                    border-bottom-color: #00ff00;
                    color: $white;
                    &:hover {
                        background: darken($success, 5%);
                        color: $white;
                    }
                }
            }
        }
        #{$base}-form-reserved-inner-service-date {
            background: darken($grayLighter, 10%);
            padding: .8em;
            display: block;
            margin: -1.2em -1.2em 1.2em;
        }
    }
    // SECTION
    #{$base}-section {
        position: relative;
        background: lighten($grayLighter, 5%);
        border: 1px solid $grayLighter;
        @include box-sizing(border-box);
        margin-bottom: 20px;
        h2 {
            margin-top: 0;
            margin-bottom: 25px;
            font-weight: $eFontWeightBold;
            font-size: $baseFontSize + 4;
        }
        .master-service-bookservice-reset-booking {
            float: right;
            display: none;
        }
        h5 {
            margin-bottom: 7px;
            font-size: $baseFontSize - 3;
        }
        #{$base}-list-disabled {
            display: none;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 9999;
            cursor: wait;
        }
        ul#{$base}-servicelist {
            list-style-type: none;
            margin: 0;
            padding: 0;
            &.loading {
                pointer-events: none;
            }
            li {
                position: relative;
                width: 100%;
                background: lighten($grayLighter, 1%);
                @include box-sizing(border-box);
                @include border-radius(3px);
                width: 49%;
                float: left;
                padding: 0 10px;
                height: 4em;
                margin-bottom: 10px;
                &:not(.no-margin) {
                    @media(min-width: 768px) {
                        &:nth-child(2n) {
                            margin-left: 2%;
                        }
                    }
                }
                @media(max-width: 767px) {
                    width: 100%;
                    float: none;
                }
                input[type="checkbox"] {
                    position: absolute;
                    left: -9999px;
                    @include opacity(0);
                    + label {
                        display: flex;
                        align-items: center;
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        padding: 0 100px 0 50px;
                        line-height: 18px;
                        @include box-sizing(border-box);
                        width: 100%;
                        font-size: $baseFontSize - 2;
                        margin: 0;
                        span {
                            display: flex;
                            align-items: center;
                            justify-content: flex-end;
                            position: absolute;
                            font-weight: $eFontWeightBold;
                            font-size: $baseFontSize - 3;
                            width: 46px;
                            height: 64px;
                            top: 0;
                            right: 37px;
                            text-align: left;
                        }
                        &:before {
                            top: 17px;
                            left: 10px;
                        }
                        p {
                            margin: 0;
                            width: 90%;
                        }
                        span.label-campaign {
                            position: absolute;
                            width: 100px;
                            height: 20px;
                            top: -4px;
                            left: -5px;
                            background: $info;
                            color: $white;
                            @include border-radius(2px);
                            border-bottom: 2px solid darken($info, 5%);
                            font-size: $baseFontSize - 2;
                            text-align: center;
                            font-weight: $eFontWeightBold;
                            font-family: $masterSecondaryFontFamily;
                            line-height: 23px;
                            text-transform: uppercase;
                            transform: rotate(-5deg);
                            justify-content: center;
                        }
                        #{$base}-servicelist-in-cart-label {
                            position: absolute;
                            width: 29px;
                            height: 26px;
                            top: -8px;
                            right: -5px;
                            background: lighten($grayLight, 5%);
                            color: $white;
                            @include border-radius(2px);
                            border-bottom: 2px solid darken($grayLight, 5%);
                            font-size: $baseFontSize - 2;
                            text-align: center;
                            font-weight: $eFontWeightBold;
                            font-family: $masterSecondaryFontFamily;
                            line-height: 23px;
                            text-transform: uppercase;
                            transform: rotate(5deg);
                            z-index: 2;
                        }
                    }
                    &:disabled {
                        + label {
                            opacity: .6;
                        }
                    }
                    &:focus + label {
                        outline: 1px dotted $gray;
                    }
                    &:checked {
                        + label {
                            background: $success;
                            color: $white;
                            &:before {
                                @extend .material-icons;
                                content: "done";
                                color: $success;
                                font-size: 28px;
                                border: 1px solid $white;
                            }
                            + em {
                                &:after {
                                    color: lighten($success, 15%);
                                }
                            }
                        }
                    }
                }
                em {
                    position: absolute;
                    width: 30px;
                    height: 4em;
                    line-height: 4em;
                    top: 0;
                    right: 0;
                    cursor: pointer;
                    &:after {
                        @extend .material-icons;
                        content: "info_outline";
                        font-size: 20px;
                        line-height: 50px;
                        color: $grayLight;
                    }
                    &:hover {
                        @include transition(all, 0.25s);
                        @include animate('panelEntry 0.25s 1 ease-in-out');
                        &::after {
                            color: $success;
                        }
                    }
                }
                &.active {
                    background: $success;
                    color: $white;
                    em:after {
                        color: $white;
                    }
                }
                &:hover {
                    @include transition(all, 0.25s);
                    background: darken($grayLighter, 3%);
                }
                &:before {
                    top: 10px;
                    left: 10px;
                }
            }
            li#{$base}-servicelist-service-item {
                width: 100%;
                height: 4em;
                input[type="checkbox"] {
                    position: absolute;
                    left: -9999px;
                    @include opacity(0);
                    + label {
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: 0;
                        padding: 0 100px 0 50px;
                        font-size: $baseFontSize + 2;
                        font-weight: $eFontWeightBold;
                    }
                }
                [data-tooltip] + .tooltip {
                    top: 75px;
                }
            }
            li.featured-item {
                box-shadow: 0px 0px 0px 1px #ef7d00, 3px 3px 3px rgba(0, 0, 0, 0.3);
            }
        }
    }
    //CHECK AND RADIO LISTS
    ul#{$base}-extraslist {
        list-style-type: none;
        margin: 0 0 25px 0;
        padding: 0;
        @media(min-width: 768px) and (max-width: 979px) {
            border-bottom: 1px solid $grayLighter;
            padding-bottom: 10px;
        }
        li {
            position: relative;
            width: 100%;
            min-height: 30px;
            @media(min-width: 768px) and (max-width: 979px) {
                float: left;
                width: 49%;
                &:nth-child(even) {
                    margin-left: 2%;
                }
            }
            input[type="checkbox"] {
                position: absolute;
                left: -9999px;
                @include opacity(0);
                + label {
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    margin-left: 40px;
                    width: calc(100% - 40px);
                    font-size: $baseFontSize - 3;
                    line-height: $baseLineHeight - 8;
                    align-items: center;
                    &:before {
                        top: 0;
                        left: 0;
                    }
                    &[data-rental-price] {
                        &:after {
                            content: attr(data-rental-price);
                            display: block;
                            float: left;
                            font-size: .85em;
                            color: $textColorMuted;
                        }
                    }
                }
                &:checked {
                    + label {
                        &:before {
                            @extend .material-icons;
                            content: "done";
                            color: $success;
                            font-size: 28px;
                        }
                    }
                }
                &:disabled {
                    + label {
                        color: $grayLight;
                        &:before {
                            background-color: $grayLighter;
                        }
                    }
                }
            }
            input[type="checkbox"]:focus + label {
                &:before,
                &:after {
                    border-color: $success;
                }
            }
        }
    }
    ul#{$base}-extraslist,
    ul#{$base}-servicelist {
        li {
            input[type="checkbox"] {
                margin-left: -9999em;
            }
            input[type="checkbox"] + label {
                &:before {
                    position: absolute;
                    left: -40px;
                    content: "";
                    width: 30px;
                    height: 30px;
                    background: $white;
                    @include box-sizing(border-box);
                    @include border-radius(3px);
                    border: 1px solid $baseBorderColor;
                }
            }
        }
    }
    // TEXTAREA

    textarea#{$base}-textarea {
        padding: 15px 20px;
        min-height: 200px;
        &:focus {
            outline: 1px dotted $gray;
        }
    }
    textarea#{$base}-textarea--narrow {
        padding: 5px 10px;
        min-height: 100px;
        font-size: $baseFontSize - 3;
        &:focus {
            outline: 1px dotted $gray;
        }
    }
    #{$base}-textarea-append {
        position: relative;
        background: lighten($grayLighter, 1%);
        padding: 15px 20px 20px 20px;
        &:after {
            position: absolute;
            content: " ";
            bottom: 100%;
            left: 20px;
            border: solid transparent;
            height: 0;
            width: 0;
            pointer-events: none;
            border-bottom-color: lighten($grayLighter, 1%);
            border-width: 15px;
        }
        p {
            font-size: $baseFontSize - 5;
            font-style: italic;
            color: $textColorMuted;
            line-height: $baseLineHeight - 7;
            margin-bottom: 0;
            strong {
                display: block;
                margin-bottom: 5px;
            }
        }
    }
    #{$base}-sidebar {
        &:before,
        &:after {
            content: '';
            @include transition(top .2s ease);
            top: 0px;
        }
        &.no-available-dates {
            position: relative;
            &:before {
                @extend .material-icons;
                content: 'info_outline';
                position: absolute;
                top: 97px;
                left: 30px;
                z-index: 10;
                color: $white;
                @include animate('zoomPulse 1s infinite ease-in-out');
            }
            &:after {
                content: attr(data-no-dates);
                position: absolute;
                top: 70px;
                background: $error;
                color: $white;
                margin: 1em;
                @include box-sizing(border-box);
                @include box-shadow(0 0 5px rgba($black, .3));
                padding: 1em 1em 1em 3em;
                font-size: 0.9em;
                line-height: 1.3em;
            }
        }
    }
    // INPUT TEXT
    #{$base}-section {
        input[type="text"]:not(.module-licenseplate-lookup-input),
        input[type="number"],
        input[type="email"],
        input[type="password"] {
            @include border-radius(0);
            @include box-shadow(none);
            @include box-sizing(border-box);
            width: 100%;
            height: 60px;
            line-height: 60px;
            padding: 0 10px;
            border: 1px solid $baseBorderColor;
            margin-bottom: 25px;
            &:focus {
                border: 1px dotted $gray;
            }
        }
    }
    #{$base}-panel-other-comment {
        margin: 25px 0 0 0;
    }
    // LICENSEPLATE LOOKUP MODIFIER
    #{$base}-licenseplate {
        .module-licenseplate-lookup .module-licenseplate-lookup-input[type=text] {
            position: relative;
            height: 60px;
            line-height: 60px;
            min-height: 60px !important;
            width: calc(100% - 30px);
            margin-left: 30px;
            margin-bottom: 10px;
            border-width: 2px 2px 2px 0;
            font-size: 35px;
            @media (min-width: 768px) and (max-width: 979px) {
                font-size: 30px;
            }
        }
        .module-licenseplate-lookup .module-licenseplate-lookup-prepend:before {
            height: 60px;
            width: 30px;
            border-width: 2px 0 2px 2px;
        }
    }
    // LICENSEPLATE INFO MODIFIER
    #{$base}-carinfo {
        p {
            font-size: $baseFontSize - 2;
        }
        h5 {
            margin: 0;
        }
        .module-licenseplate-info {
            color: $textColor;
            background: none;
            padding: 0 0 0 3em;
            margin-bottom: 10px;
            &:before {
                top: 0.15em;
                background: url("/files/Templates/Designs/ftzmaster/assets/img/svg-graphics/car-simple-icon.svg") top left no-repeat;
            }
            span.module-licenseplate-info-model {
                font-size: $baseFontSize - 2;
            }
            small {
                font-size: $baseFontSize - 3;
                color: $textColorMuted;
            }
        }
    }
    // SERVICE WRAPPER
    #{$base}-service-addons {
        input[type="radio"] {
            +label {
                font-size: $baseFontSize - 2;
            }
        }
        input[type="radio"]:focus + label {
            &:before,
            &:after {
                border-color: $success;
            }
        }
    }
    #{$base}-servicepack-info-wrapper {
        background: $white;
        @include box-sizing(border-box);
        @include border-radius(0 0 3px 3px);
        border: 1px solid $baseBorderColor;
        border-top-width: 0;
        padding: 10px;
        margin-bottom: 10px;
        p {
            font-size: $baseFontSize - 4;
            line-height: $baseLineHeight - 8;
        }
        a {
            font-size: $baseFontSize - 4;
            padding: 3px 20px;
            @include border-radius(50px);
            &:hover,
            &:focus,
            &:active {
                @include transition(all,
                0.15s);
                text-decoration: none;
            }
        }
    }
    #{$base}-servicepack-bronze {
        a,
        #{$base}-servicepack-container {
            background-color: #d8ac9d;
            background: $bronze;
        }
    }
    #{$base}-servicepack-silver {
        a,
        #{$base}-servicepack-container {
            background: #e0e0e0;
            background: $silver;
        }
    }
    #{$base}-servicepack-gold {
        a,
        #{$base}-servicepack-container {
            background: #cb9b50;
            background: $gold;
        }
    }
    #{$base}-servicepack-container {
        position: relative;
        @include box-sizing(border-box);
        @include border-radius(3px 3px 0 0);
        padding: 0 10px;
        height: 50px;
        input {
            +label {
                position: absolute;
                left: 0;
                right: 0;
                height: 100%;
                padding: .45em .3em 0 2.5em;
                font-weight: $eFontWeightBold;
                font-size: $baseFontSize + 6;
                font-family: $masterSecondaryFontFamily;
                @include box-sizing(border-box);
                span {
                    float: right;
                    font-weight: $eFontWeightBold;
                    font-size: $baseFontSize - 2;
                    font-family: $sansFontFamily;
                }
                &:before,
                &:after {
                    top: 10px;
                    left: 10px;
                    border: 1px solid transparent;
                }
            }
        }
        input[type="radio"]:focus + label {
            &:before,
            &:after {
                border-color: $success;
            }
        }
        input:checked {
            +label {
                &[for="service-bronze"] {
                    background-color: #d8ac9d;
                    background: $bronze;
                }
                &[for="service-silver"] {
                    background: #e0e0e0;
                    background: $silver;
                }
                &[for="service-gold"] {
                    background: #cb9b50;
                    background: $gold;
                }
            }
        }
    }
    // BASE DATETIME PICKER TOGGLE
    #{$base}-toggle {
        position: relative;
        width: 100%;
        height: 60px;
        line-height: 60px;
        background: $white;
        border: 1px solid $grayLighter;
        @include box-sizing(border-box);
        //padding: 0 10px;
        margin-bottom: 25px;
        cursor: pointer;
        p {
            margin-bottom: 0;
            font-size: $baseFontSize + 1;
            font-weight: $eFontWeightBold;
            font-family: $masterSecondaryFontFamily;
        }
        &.toggle-date:after,
        &.toggle-time:after {
            @extend .material-icons;
            position: absolute;
            top: 10px;
            right: 10px;
            width: 40px;
            height: 40px;
            content: "today";
            font-size: 40px;
            color: $textColorMuted;
            pointer-events: none;
        }
        &.toggle-date.loading:after {
            @include animate('rotate .85s infinite linear reverse');
            color: $warningComplimentary;
            content: "cached";
        }
        &.toggle-date.loading:before {
            content: "Genberegner dato";
            position: absolute;
            background: $white;
            color: $warningComplimentary;
            left: 3px;
            top: 3px;
            bottom: 3px;
            padding: 10px;
            line-height: 35px;
            vertical-align: middle;
        }
        &.toggle-date.error:before {
            content: "Ugyldig dato";
            position: absolute;
            background: $white;
            color: $warningComplimentary;
            left: 3px;
            top: 3px;
            bottom: 3px;
            padding: 10px;
            line-height: 35px;
            vertical-align: middle;
        }
        &.toggle-time:after {
            content: "query_builder";
        }
        input[type="text"] {
            background: $white;
            cursor: pointer;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
        }
    }
    &:not(#{$base}-cart-view) #{$base}-toggle-wrapper {
        .span6 {
            @media (min-width: 980px) {
                width: 100%;
                margin-left: 0;
            }
        }
    }
    #{$base}-toggle-append {
        position: relative;
        background: lighten($grayLighter, 1%);
        @include border-radius(3px);
        font-size: $baseFontSize - 5;
        font-style: italic;
        color: $textColorMuted;
        line-height: $baseLineHeight - 7;
        padding: 12px 15px 15px 65px;
        margin: 15px 0 0 0;
				min-height: 3em;
				
        p {
            margin-bottom: 0;
        }
        
        &.attention {
            @include animate('attention 1s 2 ease');
        }
    }
    @media (max-width: 979px) {
        #{$base}-content-wrapper {
            width: 100%;
            margin-left: 0 !important;
        }
    }
    .section--large {
        padding: 20px;
        @media (max-width: 767px) {
            padding: 10px;
        }
    }
    .section-small {
        padding: 10px;
    }
}

// BUTTON
#{$base}-content-wrapper {
    .master-button--large {
        background: $brandColor;
        color: $white;
        border-bottom-color: darken($brandColor, 5%);
        &:hover,
        &:active,
        &:focus {
            background: $success;
            border-bottom-color: darken($success, 5%);
        }
    }
}

#{$base}-cart-view {
    ul#{$base}-extraslist {
        border-bottom: 1px solid $grayLighter;
        padding-bottom: 10px;
        li {
            @media (min-width: 768px) {
                float: left;
                width: 49%;
                &:nth-child(even) {
                    margin-left: 2%;
                }
            }
        }
    }
}

#{$base}-disclaimer-append {
    position: relative;
    background: $white;
    border: 1px solid $grayLighter;
    font-size: $baseFontSize - 5;
    color: $textColorMuted;
    line-height: $baseLineHeight - 7;
    padding: 12px 15px 15px 65px;
    margin-bottom: 20px;
    &:after {
        @extend .material-icons;
        position: absolute;
        top: 10px;
        left: 10px;
        width: 40px;
        height: 40px;
        content: "info_outline";
        font-size: 40px;
        color: $grayLighter;
    }
    p {
        margin-bottom: 0;
    }
}

#{$base}-nobooking {
    #{$base}-nobooking-icon {
        font-size: 100px;
    }
    a.btn.master-button--mega {
        background: $success;
        border-bottom-color: darken($success, 5%);
        &:hover,
        &:focus,
        &:active {
            background: darken($success, 5%);
            border-bottom-color: darken($success, 10%);
        }
        color: $white;
    }
}

#{$base}-contact-modal {
    input[type="text"],
    textarea,
    input[type="number"],
    input[type="email"],
    input[type="password"] {
        @include border-radius(0);
        @include box-shadow(none);
        @include box-sizing(border-box);
        width: 100%;
        height: 60px;
        line-height: 60px;
        padding: 0 10px;
        border: 1px solid $baseBorderColor;
        margin-bottom: 25px;
    }
    textarea {
        height: 100px;
        //line-height: 100px;
    }
}

#{$base}-package-info {
    @media (min-width: 978px) {
        width: 1000px;
        margin-left: -500px;
    }
    #{$base}-package-info-list {
        //@include flexbox();
        #{$base}-package-info-list-item {
            width: 50%;
            float: left;
            @include box-sizing(border-box);
            padding: 1em;
            @media (max-width: 979px) {
                float: left;
                width: 100%;
            }
            h3 {
                padding: 1em;
                margin-bottom: 1.2em;
                background: $grayLighter;
            }
            &#{$base}-package-info-list-item-bronze {
                h3 {
                    background: $bronze;
                }
            }
            &#{$base}-package-info-list-item-silver {
                h3 {
                    background: $silver;
                }
            }
            &#{$base}-package-info-list-item-gold {
                h3 {
                    background: $gold;
                }
            }
            ul {
                list-style: none;
                padding: 0;
                margin: 0;
                li {
                    position: relative;
                    background: $grayLighter;
                    @include box-sizing(border-box);
                    @include border-radius(3px);
                    padding: 1em 1em 1em 4em;
                    font-size: .85em;
                    &:before {
                        @extend .material-icons;
                        position: absolute;
                        content: 'check';
                        color: $success;
                        left: .5em;
                        font-size: 2em;
                    }
                    @media (min-width: 978px) {
                        &:nth-child(3),
                        &:nth-child(5) {
                            margin-bottom: 1.5em;
                            &:after {
                                content: '';
                                position: absolute;
                                border-bottom: 1px solid $grayLighter;
                                width: 115%;
                                left: -26px;
                                bottom: -0.8em;
                            }
                        }
                    }
                }
            }
        }
    }
}

// TERMS AND CONDITIONS
#{$base} {
    .span6.termsandconditions {
        h5 {
            font-size: $baseFontSize - 3;
        }
        label {
            font-size: 13px !important;
            line-height: 18px !important;
            color: #a2a2a2;
            &:before,
            &:after {
                margin-top: 2px;
            }
        }
    }
}

// E-CHECKOUT-ACTIONS
#{$base} {
    .e-checkout-actions {
        .btn {
            background: $brandColor;
            border-bottom-color: darken($brandColor, 5%);
            color: $white;
            &:focus,
            &:active,
            &:hover {
                background: $success;
                border-bottom-color: darken($success, 5%);
            }
        }
    }
}

// DETAILS MODAL
#master-booking-service-details {
    @media (min-width: 768px) {
        width: 720px;
        margin-left: -360px;
    }
    @media (max-width: 767px) {
        max-height: 100vh;
        height: 100vh;
    }
    max-height: 80vh;
    height: auto;
    .modal-body {
        max-height: none;
    }
}

#{$baseModal}-modal-info {
    .e-productdetails-desc {
        margin: 1em 0 0;
    }
    #{$baseModal}-modal-shortDescription {
        font-size: 0.846em;
    }
    #{$baseModal}-modal-longDescription {
        ul {
            display: none;
        }
        ul.equal-columns-list {
            width: 50%;
            float: left;
            @media (max-width: 767px) {
                width: 100%;
                float: none;
                &:first-of-type {
                    margin: 0;
                }
            }
            list-style-type: none;
            @include box-sizing(border-box);
            margin: 0 0 2em 0;
            padding: 0;
            li {
                position: relative;
                padding: 0 1em 1em 2.5em;
                margin: 0;
                @include box-sizing(border-box);
                font-size: $baseFontSize - 3;
                line-height: $baseLineHeight - 6;
                &:before {
                    @extend .material-icons;
                    content: "check";
                    position: absolute;
                    font-size: 24px;
                    top: 0;
                    left: 0;
                    color: $success;
                }
            }
        }
    }
    .row-fluid .thumbnail-svg {
        position: relative;
        padding: 0;
        border: none;
        @include box-shadow(none);
        > img {
            max-height: 20em;
            @media (min-width: 768px) and (max-width: 979px) {
                max-height: 15em;
            }
            @media (max-width: 767px) {
                max-height: 10em;
            }
        }
    }
    .e-price {
        margin-top: 1.5em;
        font-size: 1.5em;
        font-weight: $eFontWeightBold;
        color: $textColor;
        small.e-before-price {
            color: $error;
            font-weight: $eFontWeightNormal;
            font-size: 0.7em;
        }
    }
}

.master-service-bookservice-toggle-append.js-booking-car-deposit-box {
    background: $brandColorSecondary !important;
    color: #fff !important;
    font-size: 16px !important;;
    line-height: 22px !important;;
    p {
        font-size: 16px !important;
        font-weight: 400 !important;
        margin: 0 !important;
    }
}

.key-icon {
	height: 30px;
	width: 30px;
	position: absolute;
	left: 15px;
}