$base: ".master-service-bookservice";

#{$base}-stepguide {
    ul {
        padding: 0;
        margin: 0 0 10px 0;
        list-style-type: none;
        li {
            position: relative;
            width: calc(33.33333% + 7px);
            @include box-sizing(border-box);
            border: 1px solid $baseBorderColor;
            border-width: 1px 0;
            height: 40px;
            line-height: 40px;
            float: left;
            text-align: center;
            font-weight: $eFontWeightLight;
            font-size: $baseFontSize + 2;
            text-indent: 14px;
            &:after {
                position: absolute;
                content: "";
                background: transparent;
                top: 5px;
                border: 1px solid $baseBorderColor;
                @include box-sizing(border-box);
                border-width: 1px 1px 0 0;
                right: -14px;
                width: 27px;
                height: 27px;
                @include rotate(45deg);
            }
            &:first-of-type {
                border-left-width: 1px;
                width: calc(33.33333% - 14px);
                @include border-radius(3px 0 0 3px);
            }
            &:last-of-type {
                border-right-width: 1px;
                @include border-radius(0 3px 3px 0);
                &:after {
                    display: none;
                }
            }
            &.active,
            &.done {
                background: $gray;
                border-width: 0;
                &:after {
                    background: $gray;
                    border-width: 0;
                    width: 28px;
                    height: 28px;
                    top: 6px;
                }
            }
            &.active {
                color: $white;
            }
            &.done {
                background: $success;
                color: $white;
                &:after {
                    background: $success;
                    z-index: 1;
                }
                &:before {
                    @extend .material-icons;
                    content: "done";
                    position: absolute;
                    top: 0;
                    right: 00px;
                    width: 40px;
                    height: 40px;
                    font-size: 30px;
                    line-height: 40px;
                    z-index: 2;
                }
            }
            span {
                display: none;
            }
        }
    }
}

.checkout-mailchimp-signup {

    a {
        text-decoration: underline;
    }
}

.checkout-mailchimp-signup__rich {
    img + p {
        margin-top: 10px;
    }

}

@media (max-width: 767px) {}

@media (max-width: 767px) {
    #{$base}-stepguide {
        margin-bottom: 0 !important;
        ul {
            margin: 0 -10px;
            padding: 0;

            li,
            li:first-of-type,
            li:last-of-type {
                display: none;

                &.active {
                    display: block;
                    margin: -2em 0;
                    width: 100%;
                    text-indent: 0;
                    border-width: 0px;
                    background: #333;
                    text-align: left;
                    padding: 0 10px;
                    font-size: 16px;
                    border-radius: 0;
                    height: 33px;
                    line-height: 33px;

                    span {
                        display: inline-block;
                        margin-right: 13px;
                    }

                    &:after {
                        display: none;
                    }
                }
            }
        }
    }
}