$base: '.master-card-row-flex';
#{$base} {
    @include flexbox();
    @include flex-direction(row);
    @include justify-content(space-between);
    font-size: 1em;
    #{$base}-column {
        @include flexbox();
        @include flex-direction(column);
        @include flex(1);
        position: relative;
        margin-left: 1.25em;
        background: url("/files/Templates/Designs/ftzmaster/assets/img/svg-graphics/graphic-card-pattern.svg");
        background-size: cover;
        background-color: #333;
        padding: 2em;
        color: $white;
        text-align: center;
        img,
        svg {
            fill: $white;
            max-width: 120px;
            max-height: 150px;
            margin: 0 auto;
            display: table;
        }
        #{$base}-column-end {
            @include flex-grow(1);
            margin-bottom: 1em;
        }
        #{$base}-column-header {
            color: $white;
            font-size: 2em;
            margin-top: 0.75em;
            font-weight: $eFontWeightBold;
        }
        #{$base}-column-callout {
            font-weight: $eFontWeightBold;
            font-size: 1.5em;
            color: $brandColorSecondary;
        }
        ul {
            text-align: left;
            padding: 0 0.5em;
        }
        #{$base}-column-button {
            background: $success;
            border-bottom: .3em solid darken($success, 5%);
            color: $white;
            width: 50%;
            margin: 0 auto;
            &:hover,
            &:active,
            &:focus {
                background: darken($success, 5%);
                border-bottom: 0.3em solid darken($success, 10%);
            }
        }
    }
    img#{$base}-corner-graphics {
        position: absolute;
        width: 75px;
        height: 75px;
        top: -6px;
        right: -6px;
    }
    @media (max-width: 767px) {
        display: block;
        #{$base}-column {
            display: block;
            margin: 0 0 1em 0;
        }
    }
}

.full-card-link {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
}
