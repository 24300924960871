.btn + .btn,
.btn:visible + a[href],
.btn:visible + .btn {
    margin-left: $gridGutterWidth;
}

// Buttons
// --------------------------------------------------
// Base styles
// --------------------------------------------------
// Core
.btn,
.e-bottom .btn {
    padding: 0.55em 1.25em 0.375em 1.25em;
    border: none;
    background: transparent;
    @include border-radius(0);
    color: $textColor;
    font-family: $headingsFontFamily;
    text-shadow: none;
    box-shadow: none;
    text-decoration: none;
    font-weight: $eFontWeightNormal;
    @include transition(all 0.25s);
    &:hover {
        @include transition(all 0.25s);
        background: $brandColor;
        color: $white;
    }
}

.btn:hover,
.e-bottom .btn:hover {
    transition: none;
}

.btn {
    &:hover {
        text-decoration: none;
        &:hover,
        &:focus {
            color: $white;
        }
    }
}

.btn-error {
    background: $error;
    color: $white;
    &:hover {
        background: darken($error, 10%);
    }
}

.btn-small {
    background: $grayLighter;
    border-bottom: 0.3em solid darken($grayLighter, 5%);
    &:hover {
        background: $success;
        border-bottom: 0.3em solid darken($success, 5%);
    }
}

// BUTTON MEGA/LARGE
.master-button--mega,
.master-button--large,
.master-button--campaign {
    font-size: 2em;
    font-weight: $eFontWeightBold;
    padding: .95em 1.75em .85em 1.75em;
    background: $white;
    border-bottom: 0.2em solid darken($white, 10%);
    @media (max-width: 767px) {
        font-size: 1.5em;
    }
    &:hover,
    &:focus,
    &:active {
        color: $white;
        background: $success;
        border-bottom: 0.2em solid darken($success, 5%);
    }
}

.master-button--large {
    font-size: 1.5em;
    padding: .75em 2em .65em 2em;
    &.master-button--large--added {
        background: $success;
        border-bottom: 0.2em solid darken($success, 5%);
        color: $white;
    }
}

.master-button--campaign {
    background: $brandColor;
    border-bottom-color: darken($brandColor, 5%);
    color: $white;
}

// BUTTON ECOM GRID
.master-button--grid {
    position: absolute;
    @include box-sizing(border-box);
    left: 0;
    bottom: 1em;
    margin: 0;
    text-align: left;
    width: 100%;
    padding: 1em 1em 0;
    .btn {
        @include border-radius(50px);
        @include box-sizing(border-box);
        width: 100%;
        font-size: 1em;
        border: none;
        &.master-button--grid--inbasket {
            background: $success;
            color: #fff;
            border-bottom: 0.25em solid darken($success, 5%);
        }
        &.master-button--grid--seemore {
            background: $grayLighter;
            color: $textColor;
            border-bottom: 0.25em solid darken($grayLighter, 5%);
            &:hover,
            &:active,
            &:focus {
                @include transition(all .25s);
                color: $white;
                background: $success;
                border-bottom-color: darken($success, 5%);
            }
        }
    }
}

ul.list-view li .master-button--grid {
    @media (min-width: 768px) {
        width: 200px;
        left: auto;
        right: 0;
    }
}


[data-link]{
    cursor: pointer;
}