$base: ".master-webshop-add-to-cart";
form#{$base} {
    input[type="number"],
    button[type="submit"] {
        margin: 0;
        height: 40px;
        @include box-sizing(border-box);
    }
    input[type="number"] {
        width: 40%;
        display: block;
        float: left;
        margin-bottom: 0;
        border-right: none;
        @include box-shadow(none);
        text-align: center;
        @include border-radius(0);
        border-color: $baseBorderColor;
    }
    button[type="submit"] {
        width: 60%;
        padding: 0.25em 0.25em 0 0.25em;
        font-size: 1em;
        border: none;
        background: $brandColor;
        color: $white;
        @include border-radius(0);
        border-bottom: 0.25em solid darken($brandColor, 5%);
        &:hover {
            @include transition(all 0.25s);
            background-color: $success;
            border-bottom: 0.25em solid darken($success, 5%);
        }
    }

    fieldset:disabled button[type="submit"] {
        background: $grayLighter;
        border-bottom: 0.25em solid darken($grayLighter, 5%);
        color: $grayLight;
    }
}