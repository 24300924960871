// THEME COLORS

$brandColor: 			#4b4b4b;
$brandColorSecondary: 	#eb1c24;
$brandColorLightCustom:	#edebe9;
$info: 					#7ea4b2;
$success: 				$brandColorSecondary;
$successColorCustom: 	#4a9949;
$warning: 				#ffd100;
$warningComplimentary: 	$brandColorSecondary;
$error: 				$brandColorSecondary;

//----------- DONT TOUCH ----------------------------//

$brandColorLight: lighten(desaturate($brandColor, 20%), 5%);
$brandColorLighter: lighten(desaturate($brandColor, 47%), 32%);
$brandColorLightest: lighten(desaturate($brandColor, 12%), 83%);




// Typography
// -------------------------



@font-face {
    font-family: 'klavikamedium';
    src: url('../../fonts/klavika_medium-webfont.woff2') format('woff2'),
         url('../../fonts/klavika_medium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}


@import url('https://fonts.googleapis.com/css?family=Barlow+Semi+Condensed:400,800');

@import url('https://fonts.googleapis.com/css?family=Merriweather');

@import url('https://fonts.googleapis.com/css?family=Ubuntu+Condensed');


$sansFontFamily: "Barlow Semi Condensed", "Helvetica Neue", Helvetica, Arial, sans-serif;
$serifFontFamily: "Merriweather", Georgia, "Times New Roman", Times, serif;
$defaultFontFamily: $serifFontFamily;
$monoFontFamily: Menlo, Monaco, Consolas, "Courier New", monospace;
$masterFontFamily: $sansFontFamily;
$headingsFontFamily: $masterFontFamily;
$masterSecondaryFontFamily: "Barlow Semi Condensed", "Roboto", "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;


$workshop-badges: (
    webshop: #50727a,
    booking: $grayDark,
    both: $brandColorSecondary,
    selected: $grayDark
);


// Inverted navbar

$navbarInverseText: $grayDark;
$navbarInverseLinkColor: $navbarInverseText;
$navbarInverseLinkColorHover: $brandColorSecondary;
$navbarInverseLinkColorActive: $brandColorSecondary;
$navbarInverseLinkBackgroundHover: transparent;
$navbarInverseLinkBackgroundActive: transparent;

//----------- DONT TOUCH ----------------------------//

$brandColorLight: lighten(desaturate($brandColor, 20%), 5%);
$brandColorLighter: lighten(desaturate($brandColor, 47%), 32%);
$brandColorLightest: lighten(desaturate($brandColor, 12%), 83%);
