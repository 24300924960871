$base: '.master-workshop-administration';
#{$base} {
    #{$base}-header {
        font-size: 1.15em;
        p.lead {
            margin: 0;
        }
        h2 {
            font-weight: $eFontWeightBold;
            margin-top: 0;
            margin-bottom: 1em;
        }
    }
    // TABS
    #{$base}-tabs {
        background: #f5f5f5;
        border: none;
        li {
            @media (max-width: 767px) {
                width: 100%;
            }
            &.disabled {
                pointer-events: none;
                @include opacity(50);
            }
        }
        li a,
        li.active a {
            position: relative;
            padding: 0 1.25em;
            @media (min-width: 768px) and (max-width: 979px) {
                padding: 0 1em;
            }
            margin: 0;
            height: 40px;
            line-height: 40px;
            @include border-radius(0);
            font-weight: $eFontWeightLight;
            font-family: $sansFontFamily;
            font-size: $baseFontSize + 2;
            background: $gray;
            color: $white;
            @media (min-width: 768px) {
                border-width: 0 1px 0 0;
            }
            @media (max-width: 767px) {
                border-bottom: 1px solid $white;
            }
        }
        li.active a {
            background: $success;
            @media (min-width: 767px) {
                &:after {
                    top: 100%;
                    left: 50%;
                    border: solid transparent;
                    content: "";
                    height: 0;
                    width: 0;
                    position: absolute;
                    pointer-events: none;
                    border-top-color: $success;
                    border-width: 8px;
                    margin-left: -8px;
                }
            }
        }
        li.active a,
        li:hover a {
            @include transition(all, 0.15s);
            background: $success;
        }
    }
    // GENERAL CONTENT STYLING
    .tab-content {
        padding: 2em 0 0 0;
        border: none;
        h2 {
            font-size: 18px;
            margin-bottom: 1em;
        }
    }
    .form-horizontal .control-label {
        text-align: left;
    }
    #{$base}-actions {
        .btn {
            font-weight: $eFontWeightBold;
            background: $success;
            border-bottom: 0.3em solid darken($success, 5%);
            color: $white;
            &:hover,
            &:active,
            &:focus {
                background: darken($success, 5%);
                border-bottom: 0.3em solid darken($success, 10%);
            }
        }
        @media (max-width: 767px) {
            .btn {
                width: 100%;
                margin-bottom: 1em;
            }
        }
    }
    #{$base}-area-toggle {
        margin-bottom: 2em;
        @include border-radius(3px);
        @media (max-width: 767px) {
            @include border-radius(0 0 3px 3px);
        }
        background: $grayLighter;
        #{$base}-area-toggle-switch,
        #{$base}-area-toggle-info {
            position: relative;
            @include box-sizing(border-box);
            float: left;
            width: 50%;
            padding: 25px 30px;
            min-height: 175px;
            @media (min-width: 768px) and (max-width: 979px) {
                min-height: 220px;
            }
            @media (max-width: 767px) {
                width: 100%;
                min-height: auto;
            }
            &.no-toggle {
                width: 100%;
                min-height: auto;
            }
        }
        #{$base}-area-toggle-info {
            p {
                margin-bottom: 0;
                strong {
                    display: block;
                }
                span {
                    font-size: $baseFontSize - 3;
                    line-height: $baseLineHeight - 8;
                }
            }
        }
        #{$base}-area-toggle-switch {
            @include border-radius(3px 0 0 3px);
            @media (max-width: 767px) {
                @include border-radius(3px 3px 0 0);
                padding-right: 80px;
            }
            color: $white;
            h2 {
                color: $white;
                text-transform: uppercase;
                font-weight: $eFontWeightLight;
                font-size: $baseFontSize + 8;
                margin-top: 0;
            }
        }
        &.toggle-on #{$base}-area-toggle-switch,
        &.toggle-off #{$base}-area-toggle-switch {
            &:after {
                @extend .material-icons;
                position: absolute;
                top: 15px;
                right: 20px;
                width: 50px;
                height: 50px;
                font-size: 50px;
            }
        }
        &.toggle-on #{$base}-area-toggle-switch {
            background: darken($green, 10%);
            &:after {
                content: "done";
            }
        }
        &.toggle-off #{$base}-area-toggle-switch {
            background: darken($grayLight, 20%);
            &:after {
                content: "clear";
            }
        }
    }
    #{$base}-info-help {
        position: relative;
        background: $white;
        border: 1px solid $grayLighter;
        font-size: $baseFontSize - 5;
        color: $textColorMuted;
        line-height: $baseLineHeight - 7;
        padding: 12px 15px 15px 65px;
        margin-bottom: 20px;
        &:after {
            @extend .material-icons;
            position: absolute;
            top: 10px;
            left: 10px;
            width: 40px;
            height: 40px;
            content: "info_outline";
            font-size: 40px;
            color: $grayLighter;
        }
        p {
            margin-bottom: 0;
        }
    }
    #{$base}-info-help-prices {
        color: $white;
        background: $grayLight;
        @include border-radius(3px);
        padding: 3px 15px;
        display: block;
        float: right;
        font-size: $baseFontSize - 3;
        font-weight: $eFontWeightBold;
        margin-bottom: .5em;
        p {
            padding: 0;
            margin: 0;
        }
    }
    // TAB GENERAL
    #{$base}-workshop-data {
        .control-group label {
            margin-left: 0;
            display: inline-block;
            float: left;
            padding-top: 10px;
            @media (min-width: 768px) and (max-width: 979px) {
                display: block;
                width: 100%;
                font-size: 0.8em;
            }
            @media (max-width: 767px) {
                display: block;
                width: 100%;
                font-size: 0.9em;
                font-weight: $eFontWeightBold;
            }
        }
        input {
            @include box-sizing(border-box);
            @include border-radius(0);
            box-shadow: none;
            padding: 0 15px;
            border: 2px solid $grayLighter;
            outline: 2px solid transparent;
            @include transition(all 0.25s);
            outline-offset: -4px;
            min-height: 2.75em;
            &:active,
            &:focus {
                outline: 2px solid $success;
                border: 2px solid transparent;
                outline-offset: -2px;
                box-shadow: none;
            }
        }
        input[type="radio"] {
            float: left;
            min-height: 0;
            &:active,
            &:focus {
                outline: none;
            }
        }
        input.input-xlarge {
            width: 100%;
        }
        label.radio {
            display: block;
            float: none;
        }

            &.logo-selected {
                #{$base}-logo-upload-preview {
                    margin-top: 0;
                }
                #{$base}-logo-upload-remove {
                    display: inline-block;
                }
            }
            #{$base}-logo-upload-preview {
                &.logo-selected {
                    margin: 1em 0;
                }
            }
        }
        // DANMARKSKORT
        #{$base}-map {
            position: relative;
            max-height: 200px;
            img {
                opacity: 0.3;
                &:hover {
                    @include transition(opacity, 0.15s);
                    opacity: 0.7;
                }
            }
            a.jylland img {
                position: absolute;
                top: 0;
                left: 0;
                width: auto;
                height: 188px;
            }
            a.fyn img {
                position: absolute;
                top: 135px;
                left: 58px;
                width: auto;
                height: 57px;
            }
            a.sjaelland img {
                position: absolute;
                top: 100px;
                left: 102px;
                width: auto;
                height: 100px;
            }
            a.jylland.active img,
            a.fyn.active img,
            a.sjaelland.active img {
                opacity: 0.7;
            }
        }
        .control-group.region-choice {
            padding-left: 50%;
            @media (min-width: 768px) and (max-width: 979px) {
                padding-left: 65%;
            }
            height: 200px;
            padding-top: 32px;
            @media (max-width: 767px) {
                visibility: hidden;
            }
            label.radio {
                padding-left: 0;
                font-size: 1.2em;
                font-family: $sansFontFamily;
                font-weight: $eFontWeightLight;
            }
        }
        // ÅBNINGSTIDER
        #{$base}-opening-hours {
            background: $grayLighter;
            margin-bottom: 2em;
            .seventh {
                display: block;
                @include box-sizing(border-box);
                float: left;
                width: 14.285714285714285714285714285714%;
                @media (max-width: 767px) {
                    width: 100%;
                    padding: 0 1em;
                    border-bottom: 1px solid $baseBorderColor;
                }
                border-left: 1px solid $baseBorderColor;
                &:hover {
                    @include transition(all, 0.5s);
                    background: lighten($brandColor, 75%);
                }
                &:nth-of-type(6n),
                &:nth-of-type(7n) {
                    background: $grayLight;
                }
                &:nth-of-type(7) {
                    border-left: 1px solid darken($baseBorderColor, 15%);
                }
                &:nth-of-type(1) {
                    border-left: none;
                }
                .weekday {
                    display: block;
                    width: 100%;
                    text-align: center;
                    @media (max-width: 767px) {
                        text-align: left;
                    }
                    font-weight: $eFontWeightBold;
                    margin-top: 1em;
                    color: lighten($brandColor, 20%);
                }
                &:nth-of-type(6n) .weekday,
                &:nth-of-type(7n) .weekday {
                    color: $white;
                }
            }
            .control-group {
                @media (max-width: 767px) {
                    width: 36%;
                    float: left;
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }
            .control-group .controls {
                margin-left: 0 !important;
                input {
                    width: 100%;
                    background: lighten($grayLighter, 5%);
                    @include box-sizing(border-box);
                    @include border-radius(0);
                    box-shadow: none;
                    padding: 10px 15px;
                    border: none;
                    text-align: center;
                    font-weight: $eFontWeightBold;
                    font-size: 1.5em;
                    min-height: 2.5em;
                    @media (min-width: 768px) and (max-width: 979px) {
                        font-size: 1em;
                    }
                    @media (max-width: 767px) {
                        float: left;
                        font-size: 1em;
                        background: #f4f4f3;
                        border: 1px solid #ccc;
                    }
                    &:hover {
                        cursor: pointer;
                    }
                    &:focus {
                        @include box-shadow(none);
                        background: lighten($grayLighter, 8%);
                        border: none;
                        outline: none;
                    }
                }
            }
            span.sunup,
            span.sundown {
                display: block;
                width: 60px;
                height: 50px;
                margin-top: 10px;
                @media (min-width: 768px) {
                    margin-left: 50%;
                    transform: translate(-50%, 0);
                    -webkit-transform: translate(-50%, 0);
                }
                @media (min-width: 768px) and (max-width: 979px) {
                    width: 40px;
                    height: 30px;
                    margin-top: 10px;
                }
                @media (max-width: 767px) {
                    width: 9%;
                    padding: 2%;
                    height: 30px;
                    float: left;
                    margin-top: 1.75em;
                    transform: translate(0, -50%);
                }
            }
            /* SVG */
            .sunray {
                fill: none;
                stroke: #ccc;
                stroke-width: 7;
                stroke-miterlimit: 10;
            }
            .sunarc,
            .sunbar,
            .moonarc,
            .mooncloud {
                fill: #ccc;
            }
            .seventh:hover {
                /* SVG */
                .sunray,
                .sunarc,
                .sunbar,
                .moonarc,
                .mooncloud {
                    @include transition(all, 0.5s);
                }
                .sunray {
                    stroke: $warning;
                }
                .sunarc {
                    fill: $warning;
                }
                .sunbar {
                    fill: #999;
                }
                .moonarc {
                    fill: #0075d9;
                }
                .mooncloud {
                    fill: #666;
                }
            }
        }
    }
    // END TAB GENERAL
    // TAB BOOKING & SERVICEBEREGNER
    #{$base}-submit-data {
        width: 100%;
        .control-group:nth-of-type(odd) {
            background: lighten($grayLighter, 5%);
        }
        .control-group label {
            margin-left: 0;
            margin-right: 0.5em;
            width: 65%;
            display: inline-block;
            float: left;
            text-align: left;
            padding-top: 10px;
            padding-left: 0.5em;
            @media (min-width: 768px) and (max-width: 979px) {
                font-size: 0.8em;
                width: 60%;
            }
            @media (max-width: 767px) {
                font-size: 0.75em;
                width: 55%;
            }
        }
        .e-currency {
            display: block;
            float: right;
            width: 10%;
            text-align: right;
            padding-top: 10px;
        }
    }
    #{$base}-servicelist,
    #{$base}-submit-data {
        input {
            /*
            width: 6em !important;
            margin-left: 0.5em;
            @include box-sizing(border-box);
            @include border-radius(0);
            box-shadow: none;
            padding: 0px 15px;
            border: 2px solid $grayLighter;
            float: right;
            outline: 2px solid transparent;
            @include transition(all 0.25s);
            outline-offset: -6px;
            min-height: 2.75em;
            &:active,
            &:focus {
                outline: 2px solid $success;
                border: 2px solid transparent;
                outline-offset: -2px;
                box-shadow: none;
            }
            */
        }
        span#{$base}-servicelist-price {
            display: inline-block;
        }
        input#{$base}-overwrite-desc {
            width: 100% !important;
            margin-left: 0;
            font-size: 0.846em;
            margin-top: -.25em;
            margin-bottom: 1em;
            padding-left: 35px;
        }
        #{$base}-servicelist-free-service {
            float: right;
            margin: 0 0 0 3em;
            width: 5em;
            text-align: left;
            padding-top: 3px!important;
            input[type="checkbox"] {
                position: absolute;
                @include opacity(0);
                left: -9999px;
                +label {
                    font-size: 1em;
                    position: relative;
                    height: 1.6em;
                    &:before {
                        position: absolute;
                        content: '';
                        width: 1em;
                        height: 1em;
                        background: $white;
                        outline: 1px solid $grayLight;
                        left: -1.45em;
                        top: .55em;
                    }
                }
                &:checked {
                    +label {
                        color: $success;
                        &:after {
                            @extend .material-icons;
                            content: 'done';
                            position: absolute;
                            padding: .33em;
                            left: -1.6em;
                            top: .1em;
                            font-size: 1.2em;
                        }
                    }
                }
                &.disabled {
                    +label {
                        pointer-events: none;
                        color: lighten($grayLight, 10%);
                        &:before {
                            background: $grayLighter;
                            outline: 1px solid $grayLighter;
                        }
                    }
                }
            }
            @media (max-width: 767px) {
                float: left;
                margin: .5em 0 .5em .7em;
                width: 100%;
                input[type="checkbox"] {
                    +label {
                        float: left;
                        width: 6em;
                        left: 1em;
                    }
                }
            }
        }
        #{$base}-overwrite-desc-icon {
            position: absolute;
            left: 10px;
            top: 6px;
            color: $grayLight;
            width: 20px;
            height: 20px;
        }
    }
    // END TAB WORKSHOP AND SERVICE
    // TAB SERVICE CATALOGUE
    #{$base}-servicelist {
        .form-input--list-item {
            @include box-sizing(border-box);
            margin-bottom: 8px;
            &:first-child {
                padding-left: 0;
            }
            span {
                font-size: .846em;
                padding-top: 10px;
                @media (max-width: 767px) {
                    padding-top: 0;
                }
            }
            &#{$base}-list-item-campaign {
                //background-color: $warning!important;
                input {
                    background: lighten($info, 20%);
                    color: $grayLighter;
                }
                .form_input--toggle {
                    + label {
                        &:before {
                            background-color: $grayLighter;
                        }
                        &:after {
                            margin-left: 36px;
                            background: #f4f4f3;
                        }
                    }
                }
                #{$base}-list-label-campaign {
                    display: inline-block;
                    padding: .3em 1em 0em 1em;
                    @include border-radius(3px);
                    border-bottom: 0.3em solid darken($info, 5%);
                    font-size: $baseFontSize - 6;
                    font-weight: $eFontWeightBold;
                    font-family: $masterSecondaryFontFamily;
                    font-style: normal;
                    text-transform: uppercase;
                    background: $info;
                    color: $white;
                    margin: 4px 0 0 2em;
                    line-height: 18px;
                    vertical-align: middle;
                    @media (max-width: 767px) {
                        margin: .5em auto .3em auto;
                        display: table;
                        + p {
                            text-align: center;
                        }
                    }
                }
                #{$base}-list-label-campaign-description {
                    display: inline-block;
                    padding: .3em .5em 0em .5em;
                    @include border-radius(3px);
                    font-size: $baseFontSize - 6;
                    color: $gray;
                    font-style: normal;
                    margin: 4px 0 0 0em;
                    line-height: 18px;
                    vertical-align: middle;
                    @media (max-width: 767px) {
                        margin: .5em auto .3em auto;
                        display: table;
                        + p {
                            text-align: center;
                        }
                    }
                }
            }
            &#{$base}-list-item-locked {
                input {
                    background: $grayLighter;
                    color: lighten($grayLight, 10%);
                }
                .form_input--toggle {
                    + label {
                        &:before {
                            background-color: $grayLighter;
                        }
                        &:after {
                            margin-left: 36px;
                            background: #f4f4f3;
                        }
                    }
                }
                #{$base}-list-label-locked {
                    display: inline-block;
                    padding: .3em 1em 0em 1em;
                    @include border-radius(3px);
                    border-bottom: 0.3em solid darken($grayLight, 5%);
                    font-size: $baseFontSize - 6;
                    font-weight: $eFontWeightBold;
                    font-family: $masterSecondaryFontFamily;
                    font-style: normal;
                    text-transform: uppercase;
                    background: $grayLight;
                    color: $white;
                    margin: 4px 0 0 2em;
                    line-height: 18px;
                    vertical-align: middle;
                    @media (max-width: 767px) {
                        margin: .5em auto .3em auto;
                        display: table;
                        + p {
                            text-align: center;
                        }
                    }
                }
            }
        }
        .form-input--list-item:nth-child(even) {
            background: lighten($grayLighter, 5%);
        }
        .form-input--list-item-right {
            #{$base}-list-header-chain-price,
            #{$base}-list-header-your-price {
                float: left;
                font-weight: $eFontWeightBold;
                padding-top: 8px;
            }
            #{$base}-list-header-chain-price {}
            #{$base}-list-header-your-price {
                width: 6em;
                margin-left: .5em;
                text-align: right;
            }
            #{$base}-list-header-placeholder {
                width: 6em;
                float: left;
                position: relative;
            }
        }
    }
    @media (max-width: 767px) {
        .form-input--list-item {
            margin-bottom: 0;
        }
        #{$base}-servicelist {
            .form-input--list-item-left {
                width: 100%;
                max-width: 100%;
                padding: 0.5em;
                border-bottom: 1px dashed $baseBorderColor;
                @include box-sizing(border-box);
                label {
                    font-size: .75em;
                    margin-bottom: 0 !important;
                }
            }
            .form-input--list-item-right {
                width: 100%;
                #{$base}-servicelist-mobile-container {
                    width: 50%;
                    float: left;
                    @include box-sizing(border-box);
                    padding: 5px 8px;
                    input {
                        width: 100% !important;
                        margin-left: 0;
                    }
                    em {
                        display: block !important;
                        font-size: $baseFontSize - 3;
                        font-weight: $eFontWeightBold;
                        font-style: normal;
                    }
                    #{$base}-servicelist-mobile-container-flex {
                        @include flexbox();
                        @include flex-direction(column-reverse);
                    }
                }
            }
        }
    }
    // TAB BANNERS
    #{$base}-banner-board {
        position: relative;
        min-height: 600px;
        @media (min-width: 768px) and (max-width: 979px) {
            min-height: 460px;
        }
        @media (max-width: 767px) {
            min-height: auto;
        }
        input[type="radio"] {
            margin-left: -9999em;
        }
        label[for*='workshopBanner'] {
            position: absolute;
        }
        label[for="workshopBanner1"] {
            top: 0;
            left: 154px;
            width: 191px;
            height: 159px;
            @media (min-width: 768px) and (max-width: 979px) {
                left: 125px;
                width: 145px;
                height: 120px;
            }
        }
        label[for="workshopBanner2"] {
            top: 0;
            left: 400px;
            width: 128px;
            height: 128px;
            @media (min-width: 768px) and (max-width: 979px) {
                left: 315px;
                width: 100px;
                height: 100px;
            }
        }
        label[for="workshopBanner3"] {
            top: 0;
            left: 0px;
            width: 102px;
            height: 383px;
            @media (min-width: 768px) and (max-width: 979px) {
                width: 78px;
                height: 292px;
            }
        }
        label[for="workshopBanner4"] {
            top: 210px;
            left: 154px;
            width: 204px;
            height: 64px;
            @media (min-width: 768px) and (max-width: 979px) {
                top: 160px;
                left: 125px;
                width: 155px;
                height: 48px;
            }
        }
        label[for="workshopBanner5"] {
            top: 325px;
            right: 0px;
            width: 465px;
            height: 57px;
            @media (min-width: 768px) and (max-width: 979px) {
                top: 248px;
                width: 352px;
                height: 43px;
            }
        }
        label[for="workshopBanner6"] {
            bottom: 0;
            right: 0px;
            width: 100%;
            height: 160px;
            @media (min-width: 768px) and (max-width: 979px) {
                height: 120px;
            }
        }
        input:checked + label {
            @include box-sizing(border-box);
            @include box-shadow(5px 5px 0 rgba(0, 0, 0, 0.25));
        }
        label {
            &:hover,
            &#{$base}-banner-board-item-active {
                @include transition(all, 0.15s);
                @include box-shadow(5px 5px 0 rgba(0, 0, 0, 0.15));
            }
        }
    }
    #{$base}-banner-code {
        position: relative;
        overflow: hidden;
        .preview-banner {
            position: absolute;
            left: 1px;
            top: 1px;
            padding: .2em .5em;
            background: darken($grayLighter, 5%);
            font-size: .65em;
            line-height: 2em;
            border-left-width: 0;
            border-top-width: 0;
            text-decoration: none;
            @include transition(all .25s);
            &:hover {
                background: darken($grayLighter, 8%);
            }
        }
        .copy-to-clipboard {
            position: absolute;
            right: 1px;
            top: 1px;
            padding: .2em .5em;
            background: darken($grayLighter, 5%);
            font-size: .65em;
            line-height: 2em;
            border-right-width: 0;
            border-top-width: 0;
            text-decoration: none;
            @include transition(all .25s);
            &:hover {
                background: darken($grayLighter, 8%);
            }
        }
        #{$base}-banner-code-block {
            padding: 2.2em;
            display: block;
            font-size: 14px;
            background-color: #f8f8f8;
            border-color: #dfdfdf;
            border-style: solid;
            border-width: 1px;
            color: #333333;
            font-family: Consolas, "Liberation Mono", Courier, monospace;
            font-weight: normal;
            word-break: break-all;
        }
        #{$base}-banner-copy-success {
            position: absolute;
            bottom: -100px;
            @include opacity(0);
            left: 0;
            width: 100%;
            padding: 1em;
            background-color: $success;
            color: $white;
            text-align: center;
            @include box-sizing(border-box);
            @include transition(all .5s ease-in-out);
            &.slideUp {
                @include opacity(100);
                bottom: 0em;
            }
        }
    }
    #{$base}-banner-info {
        ul {
            margin: 0;
            padding: 0;
            list-style-type: none;
            li {
                position: relative;
                cursor: pointer;
                @include transition(all, 0.5s);
                @include box-sizing(border-box);
                @include border-radius(3px);
                border: 1px solid $baseBorderColor;
                padding: 0.5em;
                font-size: $baseFontSize - 3;
                strong {
                    font-size: $baseFontSize;
                    font-weight: $eFontWeightLight;
                }
                span {
                    display: block;
                    font-size: $baseFontSize - 4;
                    line-height: $baseLineHeight - 8;
                }
                em {
                    display: block;
                    font-style: normal;
                    font-weight: $eFontWeightBold;
                }
                &:hover,
                &#{$base}-banner-info-item-active {
                    @include transition(all, 0.15s);
                    background: lighten($success, 50%);
                    border: 1px solid $success;
                    color: $textColor;
                }
            }
            li#{$base}-banner-info-item-selected {
                @include transition(all, 0.15s);
                background: $success;
                border: 1px solid $success;
                color: $white;
                &:after {
                    @extend .material-icons;
                    content: "done";
                    position: absolute;
                    font-size: 30px;
                    width: 30px;
                    height: 30px;
                    line-height: 30px;
                    top: 10px;
                    right: 10px;
                }
            }
        }
    }
    #{$base}-delivery-hours {
        @include flexbox();
        @include justify-content(space-between);
        #{$base}-delivery-hours-control {
            @include flexbox();
            @include flex-direction(column);
            flex: 1;
            padding: 0 .5em 0 0;
            &:last-child{
                padding:0;
            }
        }
        @media (max-width: 767px) {
            display: block;
            #{$base}-delivery-hours-control {
                display: inline-block;
            }
        }
    }
    #{$base}-opengraph-image-upload-button {
        position: relative;
        cursor: pointer;
        display: inline-block;
        input[type="file"] {
            width: 0.1px;
            height: 0.1px;
            left: 0;
            @include opacity(0);
            overflow: hidden;
            position: absolute;
            z-index: -1;
        }
        &.opengraph-image-selected {
            #{$base}-opengraph-image-upload-preview {
                margin-top: 0;
            }
            #{$base}-opengraph-image-upload-remove {
                display: inline-block;
            }
        }
        #{$base}-opengraph-image-upload-preview {
            &.opengraph-image-selected {
                margin: 1em 0;
            }
        }
    }
    #{$base}-webmaster-wrapper {
        background-color: $grayLighter;
        padding: 1em;
        border: 1px solid $baseBorderColor;
        .control-group {
            float: left;
            margin: 0;
            input {
                width: 100%;
                border: 2px solid $baseBorderColor;
            }
        }
    }
    // END TAB BANNERS


.master-workshop-administration-opengraph-preview {
    border: 1px solid $grayLighter;
    padding: 1em;
    .master-workshop-administration-opengraph-preview-image {
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        height: 150px;
        outline: 1px solid darken($grayLighter, 10%);
        max-width: 100%;
    }
    .master-workshop-administration-opengraph-preview-url{

    }
}

// END BASE
