// BODY HTML
body,
html {
    background-color: $brandColorLightCustom;
    font-family: $serifFontFamily;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h5,
.h4,
h5,
.h5,
h6,
.h6 {
    font-weight: 400 !important;
}

h3,
.h3 {
    font-weight: 800!important;
}

.master-card-row-flex-column-header {
    font-weight: 800!important;
}

.master-card-row-flex-column-callout {
    font-weight: 400!important;
}

a.btn,
button,
input[type="submit"] {
    font-family: $sansFontFamily;
}

.e-header .navbar .nav>li>a{
    font-weight: 800!important;
}