$base: ".master-webshop-and-service-details";
$baseWebshopDetails: ".master-webshop-details";
$baseServiceDetails: ".master-service-details";

// GENERAL SHARED SETTINGS
#{$base} {
        .color--success {
            color: $successColorCustom !important;
        }

        button {
        	@include border-radius(0);
        }
}

#{$baseServiceDetails} {
	li:before {
		color: $successColorCustom !important;
	}
}



// GENERAL SHARED SETTINGS
#{$base} {

    .e-productdetails-images .row-fluid .thumbnail,
    .e-productdetails-images .row-fluid .thumbnail-svg {
        position: relative;
        padding: 0;
        border: none;
        @include box-shadow(none);
        > img {
            max-height: 20em;
            @media (min-width: 768px) and (max-width: 979px) {
                max-height: 15em;
            }
            @media (max-width: 767px) {
                max-height: 10em;
            }
        }

        #{$base}-label-campaign {
            img,
            svg {
                position: absolute;
                bottom: 0;
                left: 0;
                width: 130px;
                height: 130px;
                z-index: 1;
                pointer-events: none;
                .svgColorPrimary {
                    fill: $white;
                }
                .svgColorSecondary {
                    fill: $grayDark;
                }

            }
        }
    }
}