$base: ".master-service-streamer-healthcare";
#{$base} {
    @include box-sizing(border-box);
    background: url('/Files/Templates/Designs/ftzmaster/assets/img/rasterized-graphics/master-streamer-healthcare-PLACEHOLDER.jpg') no-repeat;
    background-position: center center;
    background-size: cover;
    padding: 3em 0;
    @media (max-width: 767px) {
        padding: 2em 2em 1em 2em;
    }
    h1 {
        font-size: 2.5em;
        @media (max-width: 767px) {
            font-size: 1.75em;
        }
        margin-top: 0;
        margin-bottom: 1em;
    }
    p {
        font-size: $baseFontSize + 2;
        margin-bottom: 1em;
    }
    a {
        &:hover {
            text-decoration: none;
        }
    }
    .btn {
        display: table;
        margin: 0 auto;
    }
    p,
    h1,
    .e-price {
        text-align: center;
        color: $white;
        text-shadow: 1px 1px 1px $black;
    }
}
