.e-cookie-message {
    color: $white;
    a {
        color: $white;
    }
    .btn {
        background-image: none;
        background-color: $white;
        color: $gray;
    }
}
