$base: ".master-frontpage-usp";
#{$base} {
    background-image: none;
    background-size: cover;
    background-position: center center;
    background-color: #333;
    padding: 3em 0;
    @media (max-width: 767px) {
        padding: 2em 2em 0 2em;
    }
}

img#{$base}-brandlogo {
    display: table;
    margin: 0 auto;
    max-width: 30em;
    max-height: 10em;
    @media (max-width: 767px) {
        max-width: 80%;
        max-height: 20em;
    }
    margin-top: 1em;
    margin-bottom: 4em;
}

#{$base}-paragraph {
    text-align: center;
    @media (max-width: 767px) {
        padding-bottom: 2em;
    }
    h3 {
        margin-top: 0;
        margin-bottom: 1em;
        font-family: $headingsFontFamily;
        font-size: 1.1em;
    }
    h3,
    p {
        color: $white;
    }
    small {
        font-size: $baseFontSize - 3;
        color: $grayLighter;
    }

    a {
        color: $brandColorSecondary;
    }
    svg {
        max-width: 5em;
        max-height: 5em;
        .svgColorPrimary {
            fill: $brandColorSecondary;
        }
        .svgColorSecondary {
            fill: $brandColorSecondary;
        }
    }
}
