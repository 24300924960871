$base: ".dfv-administration-newservices";

#{$base}-wrapper {
  border: 1px solid $baseBorderColor;
  box-shadow: inset 0 15px 35px 0 rgba(0, 0, 0, .05);
  display: flex;
  flex-wrap: wrap;
  padding: 1.5em .9em;

  .form-inner-section {
    box-sizing: border-box;
    padding: 0 1em;

    &.width-50 {
      flex: 0 0 50%;
    }

    &.width-100 {
      flex: 0 0 100%;
		}

    &.form-input--medium {
      input[type=text] {
        @include box-sizing(border-box);
        @include border-radius(0);
        box-shadow: none;
        padding: 0 15px;
        border: 2px solid $grayLighter;
        outline: 2px solid transparent;
        @include transition(all 0.25s);
        outline-offset: -4px;
        min-height: 2.75em;
        width: 100%;
        &:active,
        &:focus {
          outline: 2px solid $success;
          border: 2px solid transparent;
          outline-offset: -2px;
          box-shadow: none;
        }
      }
      .control-group.error input,
      .control-group.error select,
      .control-group.error textarea {
        &:focus,
        &:active {
          @include transition(all 0.25s);
          outline: 2px solid $error;
          box-shadow: none;
          outline-offset: 0px;
          border: 1px solid transparent;
        }
      }
      .control-group:last-child {
        margin-bottom: 12px;
      }
    }

    input[type="checkbox"] {
      width: 1.5em !important;
      min-height: 1.5em;
      float: left;
      margin-right: 0;
      &.disabled {
        pointer-events: none;
        opacity: .5;
        + label {
          pointer-events: none;
          opacity: .5;
        }
      }
    }
    .input-label {
      float: right;
      margin-left: 2em;
      label {
        float: right;
        margin-bottom: 0;
        line-height: 31px;
        font-size: 13px;
      }
		}

    .input-description {
      display: inline-block;
			font-size: 14px;
			
      small {
				display: block;
        color: #999;
			}

			&.char-count {
				display: flex;
				flex-direction: column;
				
				@media only screen and (min-width: 768px) {
					flex-direction: row;
					justify-content: space-between;
				}
			}
		}
								
}

  .time-selection-wrapper {
    position: relative;
    border: 1px solid #ddd;
    @include border-radius(100px);
    display: block;
    line-height: 46px;
    height: 46px;
    margin-top: 10px;
    text-align: center;
    font-weight: 900;
    font-size: 17px;
    .time-selection-decrease,
    .time-selection-increase {
      position: absolute;
      width: 46px;
      height: 46px;
      line-height: 46px;
      top: 0;
      left: 0;
      font-size: 30px;
      color: #ccc;
      user-select: none;
      &:hover {
        color: $success;
        @include transition(color 0.15s ease);
        cursor: pointer;
      }
    }
    .time-selection-increase {
      left: auto;
      right: 0;
    }
    &.disabled {
      pointer-events: none;
      opacity: .5;
    }
  }


}

.custom-icon-selector {

  .flex-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    border: 1px solid $baseBorderColor;
  }
  span.image-radio-button {
    position: relative;
    flex: 0 0 20%;
    background-color: $white;
    padding-top: 20%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    border: 1px solid $baseBorderColor;
    outline: 3px solid transparent;
    background-image: url('/Files/Templates/Designs/ftzmaster/assets/img/icons-service/icon-servicecalculator.svg');
    background-size: 90%;
    background-position: center center;
    background-repeat: no-repeat;
    &:hover,
    &.is-selected{
      position: relative;
      z-index: 10;
      border: 1px solid $success;
      outline: 1px solid $success;
      cursor: pointer;
      transition: all 0.25s ease-in-out;

      &::after {
        position: absolute;
        top: 0;
        right: 0;
        width: 22px;
        height: 22px;
        background: $success;
        @extend .material-icons;
        content: "done";
        color: $white;
        font-size: 20px;
        line-height: 1.1;
        margin-right: -2px;
        transition: all 0.25s ease-in-out;
      }
    }

    input[type=radio] {
      position: absolute;
      top: 0;
      width: 100% !important;
      height: 100%;
      margin: 0;
      //visibility: hidden;
      opacity: 0.01;
    }
  }
}

#{$base}-modal {
  min-height: 80vh;
  top: 50%;
  transform: translate(0,-50%);
  width: 980px;
  margin-left: -490px;
  .modal-body {
    max-height: 80vh;
  }

  @media (max-width: 979px) {
    width: 90vw;
    margin-left: -45vw;

    .form-inner-section {
      flex: 0 0 100% !important;
    }
  }
  @media (max-width: 767px) {
    left: auto;
  }

}


.accordion-group{
  .visible-if-disabled {
    color: $warningComplimentary;
    display:none;
  }
  &.disable-row{
    .visible-if-disabled {
      display:block;

    }
  }
}
