$base: ".master-webshop-and-service-details";
$baseWebshopDetails: ".master-webshop-details";
$baseServiceDetails: ".master-service-details";
// GENERAL SHARED SETTINGS
#{$base} {
    h1 {
        margin: 0 0 1em 0;
        @media (max-width: 767px) {
            text-align: center;
        }
        a:hover,
        a:focus,
        a:active {
            text-decoration: none;
        }
    }
    form {
        //width: 70%;
        @media (min-width: 768px) and (max-width: 979px) {
            width: 100%;
        }
    }
    .e-price {
        margin-top: 1.5em;
        font-size: 1.5em;
        font-weight: $eFontWeightBold;
        color: $textColor;
        small.e-before-price {
            color: $error;
            font-weight: $eFontWeightNormal;
            font-size: 0.7em;
        }
    }
    .e-productdetails-images .row-fluid .thumbnail,
    .e-productdetails-images .row-fluid .thumbnail-svg {
        position: relative;
        padding: 0;
        border: none;
        @include box-shadow(none);
        > img {
            max-height: 20em;
            @media (min-width: 768px) and (max-width: 979px) {
                max-height: 15em;
            }
            @media (max-width: 767px) {
                max-height: 10em;
            }
        }
        #{$base}-badge {
            img,
            svg {
                position: absolute;
                bottom: -0.5em;
                right: -0.5em;
                width: 120px;
                height: 120px;
                @media (min-width: 768px) and (max-width: 979px) {
                    width: 90px;
                    height: 90px;
                }
                @media (max-width: 767px) {
                    bottom: 0;
                    right: 0;
                    width: 70px;
                    height: 70px;
                }
                @include animate('zoomPulse 1s 1 ease-in-out');
                .svgColorPrimary {
                    fill: $brandColor;
                }
                .svgColorSecondary {
                    fill: $brandColorSecondary;
                }
                .svgColorTertiary {
                    fill: lighten(desaturate($brandColor, 20%), 10%);
                }
            }
        }
        #{$base}-label-campaign {
            img,
            svg {
                position: absolute;
                bottom: 0;
                left: 0;
                width: 130px;
                height: 130px;
                z-index: 1;
                pointer-events: none;
                .svgColorPrimary {
                    fill: $white;
                }
                .svgColorSecondary {
                    fill: $info;
                }
            }
        }
    }
    .e-productdetails-action fieldset.disabled,
    button.disabled {
        @include opacity(30);
    }
    .e-productdetails-desc {
        margin: 1em 0 0;
    }
    #{$base}-back-button {
        position: absolute;
        display: block;
        @include box-sizing(border-box);
        padding: 0 0.6em;
        top: 5px;
        left: 0;
        height: 40px;
        min-width: 40px;
        background: $brandColor;
        line-height: 40px;
        color: $white;
        font-size: 1.5em;
        text-align: center;
        span.small-screens {
            display: none;
        }
        &:after {
            position: absolute;
            content: "";
            bottom: -10px;
            left: 0;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 10px 10px 0;
            border-color: transparent lighten($brandColor, 10%) transparent transparent;
        }
        @media (min-width: 1640px) {
            margin-left: -110px;
        }
        @media (min-width: 1321px) and (max-width: 1639px) {
            margin-left: -90px;
        }
        @media (min-width: 1240px) and (max-width: 1320px) {
            margin-left: -70px;
        }
        @media (min-width: 768px) and (max-width: 1239px) {
            margin-left: 0;
            margin-top: -50px;
            font-size: 0.846em;
            @include border-radius(50px);
            height: 30px;
            line-height: 30px;
            padding: 0 1em;
            top: 10px;
            background: lighten($brandColor, 25%);
            &:after {
                display: none;
            }
            span.small-screens {
                display: inline-block;
            }
        }
        @media (max-width: 767px) {
            display: none;
        }
    }
}

#{$base}-price--animate {
    @include animate('blink 0.5s 1 ease-in-out');
}

// SIDEBAR PRODUCTS
.e-ecom-grid#{$base}-sidebar-grid {
    h3 {
        margin-top: 0;
        margin-bottom: 1.75em;
    }
    ul li {
        width: 100%;
        border-left-width: 1px;
        @media (min-width: 768px) and (max-width: 979px) {
            height: 300px;
            &:nth-child(3n) {
                border-right-width: 1px;
            }
        }
        &:nth-child(2n) {
            border-right-width: 1px;
        }
        &:first-child {
            border-top-width: 1px;
        }
        >div:first-child {
            padding-top: 50%;
        }
        small {
            font-size: 0.7em;
            i {
                vertical-align: -.15em;
            }
        }
        form#{$base}-sidebar-add-to-cart {
            width: 70%;
            margin-left: 15%;
            @media (min-width: 768px) and (max-width: 979px) {
                width: 80%;
                margin-left: 10%;
            }
            input[type=number],
            button[type=submit] {
                height: 34px;
            }
            button[type=submit] {
                font-size: 0.846em;
            }
        }
    }
}

// WEBSHOP DETAILS SETTINGS
#{$baseWebshopDetails} {
    #{$baseWebshopDetails}-warning {
        padding: 0.5em 1em;
        @include border-radius(5px);
        background: $grayLighter;
        margin-top: 1em;
        p {
            padding-left: 6em;
            margin-top: .5em;
            font-size: 0.846em;
            margin-bottom: 0;
            color: $textColorMuted;
            a {
                color: $textColor;
                border-bottom: 1px dotted $textColorMuted;
                &:hover,
                &:focus,
                &:active {
                    text-decoration: none;
                }
            }
        }
        img,
        svg {
            @include animate('zoomPulse 0.7s 2 ease-in-out');
            float: left;
            width: 4em;
            height: 4em;
            .outer-triangle {
                fill: $warningComplimentary;
            }
            .inner-triangle {
                fill: $white;
            }
        }
    }
    #{$baseWebshopDetails}-watermark-image {
        margin-bottom: 1.5em;
        max-height: 30px;
    }
    i {
        margin-right: 8px;
    }
    #{$baseWebshopDetails}-stockstatus,
    #{$baseWebshopDetails}-stockstatus-message {
        p {
            margin-bottom: 0;
        }
    }
    #{$baseWebshopDetails}-stockstatus {
        font-weight: $eFontWeightBold;
        font-size: 0.846em;
        margin-bottom: 1.5em;
    }
    #{$baseWebshopDetails}-stockstatus-message {
        position: relative;
        padding-left: 32px;
        span:before {
            @extend .material-icons;
            content: "local_shipping";
            color: lighten($gray, 5%);
            position: absolute;
            width: 24px;
            height: 24px;
            font-size: 24px;
            top: 0;
            left: 0;
        }
    }
    // PRODUCT DETAILS TABLE
    #{$baseWebshopDetails}-table-wrapper {
        margin-top: 1.5em;
        table {
            font-family: $defaultFontFamily;
            tr td {
                padding: 2px 5px;
                font-size: $baseFontSize - 2;
                &.first {
                    width: 35%;
                }
            }
        }
    }
}

// WEBSHOP SERVICE SETTINGS
#{$baseServiceDetails} {
    #{$baseServiceDetails}-shortdescription {
        font-size: 0.846em;
    }
    #{$baseServiceDetails}-longdescription {
        ul {
            display: none;
        }
        ul.equal-columns-list {
            width: 50%;
            float: left;
            @media (max-width: 767px) {
                width: 100%;
                float: none;
                &:first-of-type {
                    margin: 0;
                }
            }
            list-style-type: none;
            @include box-sizing(border-box);
            margin: 0 0 2em 0;
            padding: 0;
            li {
                position: relative;
                padding: 0 1em 1em 2.5em;
                margin: 0;
                @include box-sizing(border-box);
                font-size: $baseFontSize - 3;
                line-height: $baseLineHeight - 6;
                &:before {
                    @extend .material-icons;
                    content: "check";
                    position: absolute;
                    font-size: 24px;
                    top: 0;
                    left: 0;
                    color: $success;
                }
            }
        }
    }
    .e-productdetails-images {
        img,
        svg {
            max-height: 20em;
            @media (min-width: 768px) and (max-width: 979px) {
                max-height: 15em;
            }
            @media (max-width: 767px) {
                max-height: 10em;
            }
        }
    }
    .e-productdetails-action form {
        @media (max-width: 767px) {
            width: 100%;
        }
    }
    #{$baseServiceDetails}-submit {
        margin-left: 0;
        background: $success;
        border-bottom: 0.3em solid darken($success, 5%);
        color: $white;
        font-weight: $eFontWeightBold;
        font-size: $baseFontSize + 2;
        @media (max-width: 767px) {
            display: table;
            margin: 0 auto;
        }
        &:hover,
        &:focus,
        &:active {
            background: darken($success, 5%);
            border-bottom-color: darken($success, 10%);
        }
    }
    #{$baseServiceDetails}-submitted {
        font-weight: $eFontWeightBold;
        font-size: $baseFontSize - 2;
        background: $grayLighter;
        border-bottom: 0.3em solid darken($grayLighter, 5%);
        @media (max-width: 767px) {
            display: table;
            margin: 0 auto;
        }
        &:hover,
        &:focus,
        &:active {
            color: inherit;
        }
    }
    #{$baseServiceDetails}-noprice-wrapper {
        @media (max-width: 767px) {
            text-align: center;
        }
        p.lead {
            margin-bottom: 0.5em;
            line-height: $baseLineHeight;
        }
    }
    // FORM
    @media (max-width: 767px) {
        .e-price {
            text-align: center;
        }
    }
}

#{$baseServiceDetails}-sidebar-submit {
    margin-left: 0;
    background: $success;
    border-bottom: 0.3em solid darken($success, 5%);
    color: $white !important;
    font-weight: $eFontWeightBold;
    font-size: $baseFontSize + 2;
    @media (max-width: 767px) {
        display: table;
        margin: 0 auto;
    }
    &:hover,
    &:focus,
    &:active {
        background: darken($success, 5%);
        border-bottom-color: darken($success, 10%);
    }
}

.master-booking-service-details-modal-image {
    .thumbnail-svg {
        .master-webshop-and-service-details-label-campaign,
        .master-webshop-and-service-details-badge {
            svg {
                position: absolute;
                bottom: 0;
                left: 0;
                width: 120px;
                height: 120px;
                @media (min-width: 768px) and (max-width: 979px) {
                    width: 90px;
                    height: 90px;
                }
                @media (max-width: 767px) {
                    bottom: 0;
                    right: 0;
                    width: 70px;
                    height: 70px;
                }
                .svgColorPrimary {
                    fill: $brandColor;
                }
                .svgColorSecondary {
                    fill: $brandColorSecondary;
                }
                .svgColorTertiary {
                    fill: lighten(desaturate($brandColor, 20%), 10%);
                }
            }
        }
    }
}
