$base: ".master-user-info-and-search";
#{$base}-workshop-lookup--toggle {
    color: $white;
    &:hover,
    &:active,
    &:visited,
    &:focus {
        color: $white;
        text-decoration: none;
    }
    span {
        position: relative;
        display: inline-block;
        height: 37px;
        line-height: 36px;
        padding: 0 37px;
        @include transition(all 0.25s);
        &:before {
            position: absolute;
            top: 0;
            left: 7px;
            @extend .material-icons;
            font-size: 2em;
            line-height: 37px;
            content: 'location_on';
        }
        .material-icons {
            color: $grayLighter;
            vertical-align: middle;
            font-size: 2em;
        }
        &:after {
            position: absolute;
            top: 0;
            right: 0;
            width: 25px;
            text-align: center;
            height: 37px;
            @extend .material-icons;
            font-size: 1.75em;
            content: 'keyboard_arrow_down';
            line-height: 37px;
        }
    }
}

#{$base}-workshop-lookup--defined {
    span {
        background:  $grayLighter;
        color: $grayDark;
        &:hover {
            background: lighten($grayLighter, 10%);
        }
        &:before {
            color: $brandColorSecondary;
        }
    }
    &:after {
        background: darken( $grayLighter, 10%);
    }
}

#{$base}-workshop-lookup--undefined {
    span {
        background: $error;
        &:hover {
            background: darken($error, 5%);
        }
        &:after {
            background: darken($error, 10%);
        }
    }
}

#{$base}-workshop-lookup-container {
    @extend .e-wrapper;
    max-width: 100%;
    margin: 0 auto;
}

#{$base}-workshop-lookup-overlay {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparent;
    z-index: $zindexModalBackdrop;
    @media (max-width: 767px) {
        background: rgba(0, 0, 0, 0.7);
    }
}

#{$base}-workshop-lookup-panel {
    position: absolute;
    width: 100%;
    background: $grayDark;
    padding: 2em 4em;
    z-index: $zindexModal;
    @media (min-width: 767px) and (max-width: 979px) {
        padding: 2em;
    }
    @media (max-width: 767px) {
        width: 96%;
        margin-left: 2%;
        padding: 2em 1em 3em 1em;
        @include box-shadow(3px 3px 0 rgba(0, 0, 0, 0.3));
    }
    margin-top: 1em;
    border: 4px solid $gray;
    @include box-sizing(border-box);
    @include box-shadow(5px 5px 0px rgba(0, 0, 0, 0.2));
    @include transition(all .4s ease-in-out);
    fieldset {
        margin: 0;
    }
    &.disabled {
        top: -40em;
        @include opacity(0);
    }
    &.enabled {
        top: 0;
        @include opacity(100);
        @media (max-width: 767px) {
            margin-top: -5em;
        }
    }
    h1 {
        color: $white;
        margin-top: 0;
        @media (max-width: 767px) {
            font-size: 1.5em;
            margin-bottom: 10px;
        }
    }
    fieldset.module-workshop-lookup {
        border: 2px solid $grayLight;
        background: $gray;
        @media (max-width: 767px) {
            border: none;
            background: none;
            padding: 0;
        }
    }
    // ARROW TOP
    &:after {
        bottom: 100%;
        right: 2em;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-bottom-color: $gray;
        border-width: 17px;
        margin-left: -17px;
    }
    @media (max-width: 767px) {
        &:after {
            border: 0;
        }
    }
}
