$base: ".master-webshop-grid";
#{$base} {
        .color--success {
            color: $successColorCustom !important;
        }

    ul li {

        &:hover,
        &:active {
            outline-color: $brandColorSecondary;
        }
    }
}


// OVERLAYS AND BADGES
#{$base} ul li {
    div a img.watermark {
        top: 0;
        margin: 0;
        max-width: 25%;
    }
    div.badge svg {
        position: absolute;
        bottom: 0;
        right: 0;
        max-height: 65px;
        max-width: 65px;
        z-index: 1;
        @include animate('zoomPulse 1s 1 ease-in-out');
        .svgColorPrimary {
            fill: $brandColor;
        }
        .svgColorSecondary {
            fill: $brandColorSecondary !important;
        }
        .svgColorTertiary {
            fill: lighten(desaturate($brandColor, 20%), 10%);
        }
    }
    span.label-campaign {
        pointer-events: none;
        svg,
        img {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 90px;
            height: 90px;
            z-index: 1;
            .svgColorPrimary {
                fill: $white;
            }
            .svgColorSecondary {
                fill: $grayDark;
            }
        }
    }
}