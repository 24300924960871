// COLORS
.color--success {
    color: $success;
}

.color--error {
    color: $error;
}

.color--info {
    color: $info;
}

// SVG
svg {
    &:hover {
        .svgColorPrimary {
            @include transition(all, 0.25s);
        }
        .svgColorSecondary {
            @include transition(all, 0.25s);
        }
    }
}

// CATEGORY OVERVIEW SVG /
a.master-webshop-category-overview-listelement--link svg,
.master-serviceoverview-list-listelement svg {
    .svgColorPrimary {
        @include transition(all, 1s);
        fill: lighten($gray, 5%);
    }
    .svgColorSecondary {
        @include transition(all, 1s);
        fill: lighten($gray, 5%);
    }
}


a.master-webshop-category-overview-listelement--link:hover,
a.master-webshop-category-overview-listelement--link:focus,
.master-serviceoverview-list-listelement:hover,
.master-serviceoverview-list-listelement:focus {
    svg {
        .svgColorPrimary {
            @include transition(all, 0.15s);
            fill: rgba($white, 0.7);
        }
        .svgColorSecondary {
            @include transition(all, 0.15s);
            fill: rgba($white, 1);
        }
    }
}

.master-webshop-menu-top-left-menu li.active a {
    svg {
        .svgColorPrimary {
            fill: rgba($white, 0.7);
        }
        .svgColorSecondary {
            fill: rgba($white, 1);
        }
    }
}

.master-webshop-menu-top-left-menu li:not(.active) a {
    &:hover,
    &:focus {
        svg {
            .svgColorPrimary {
                fill: darken($gray, 10%);
            }
            .svgColorSecondary {
                fill: darken($gray, 15%);
            }
        }
    }
}

// FOOTER SVG
.e-footer svg {
    .svgColorPrimary {
        fill: $gray;
    }
    .svgColorSecondary {
        fill: $gray;
    }
    &:hover,
    &:focus {
        .svgColorPrimary {
            fill: rgba($gray, 0.2);
        }
        .svgColorSecondary {
            fill: $brandColorSecondary;
        }
    }
}

//SPINNER
#spinner {
    .svgSpinnerColorPrimary {
        fill: $grayDark;
    }
    .svgSpinnerColorSecondary {
        fill: $grayLighter;
    }
}

// MASTER GRID / WEBSHOP AND SERVICE DETAILS SVG / MASTER WEBSHOP CHECKOUT ORDERLINE
.master-booking-service-details-modal-image svg,
.master-webshop-and-service-details .e-productdetails-images svg,
.master-webshop-grid a svg,
.master-webshop-checkout-alternative-webshop-orderline svg {
    .svgColorPrimary {
        fill: lighten($gray, 20%);
    }
    .svgColorSecondary {
        fill: lighten($gray, 5%);
    }
}


// CATEGORY OVERVIEW SVG // WEBSHOP TOP LEFT MENU SVG
.master-webshop-menu-top-left-menu a svg {
    .svgColorPrimary {
        fill: lighten($gray, 5%);
    }
    .svgColorSecondary {
        fill: lighten($gray, 5%);
    }
}


// Din Bilpartner specifics

.master-card-row-flex-column svg {
        .svgColorPrimary {
        @include transition(all, 1s);
        fill: $brandColorSecondary;
    }
    .svgColorSecondary {
        @include transition(all, 1s);
        fill: $brandColorSecondary;
    }
}
