.form-input--list {
    @include flexbox();
    @include flex-direction(column);
    .form-input--list-item {
        margin-bottom: 1em;
        .form-input--list-item-left {
            float: left;
            max-width: 50%;
            overflow: hidden;
            padding: .4em 0 0 0;
        }
        .form-input--list-item-right {
            float: right;
            input {
                width: 90px;
            }
        }
    }
}

input[type="checkbox"].static-header-checkbox {
    width: 10px !important;
    height: 10px !important;
    margin: 3px;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;
    outline: 1px solid $grayLight;
    box-shadow: none;
    font-size: 2em;
    &:checked {
        position: relative;
        outline: 1px solid $success;
        &::after {
            @extend .material-icons;
            content: 'check';
            font-size: .34em;
            position: absolute;
            top: 1px;
            left: 0;
            color: $success;
        }
    }
}

input[type="file"],
.workshop-data input[type="file"] {
    line-height: 0;
    padding: .5em 1em;
    @include box-sizing(border-box);
    max-width: 80%;
}

.form_field--select {
    @include box-shadow(none);
    @include border-radius(0);
    @include box-sizing(border-box);
    width: 100%;
    height: 60px;
    line-height: 60px;
    padding: 0 10px;
    border: 1px solid $baseBorderColor;
    margin-bottom: 15px;
}

.form_field--block {
    display: block;
    margin-bottom: 10px;
}

.form_field--float {
    float: left;
    margin-bottom: 10px;
}

.form_input--hidden {
    position: absolute;
    left: -9999px;
    @include opacity(0);
}

.form-input--large {
    input {
        box-sizing: border-box;
        padding: 0px 10px;
        outline: 2px solid transparent;
        @include transition(all 0.25s);
        outline-offset: -4px;
        min-height: 3.5em;
        &:focus,
        &:active {
            @include transition(all 0.25s);
            outline: 2px solid $success;
            box-shadow: none;
            outline-offset: -2px;
            border: 1px solid transparent;
        }
        &#LicensePlate {
            &:focus,
            &:active {
                outline: 2px solid transparent;
            }
        }
    }
    .control-group.error input,
    .control-group.error select,
    .control-group.error textarea {
        &:focus,
        &:active {
            @include transition(all 0.25s);
            outline: 2px solid $error;
            box-shadow: none;
            outline-offset: 0px;
            border: 1px solid transparent;
        }
    }
    input.input-xlarge {
        width: 100%;
    }
    input.input-mini {
        width: 30%;
    }
    #EcomOrderCustomerCity {
        width: 68%;
    }
    #EcomOrderCustomerZip {
        margin-right: 2%;
    }
}

.form_input--toggle {
    position: absolute;
    left: -9999px;
    @include opacity(0);
    @include box-sizing(border-box);
    +label {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        position: relative;
        float: left;
        padding: 3px 0 0 80px;
        width: 100%;
        height: 32px;
        @include box-sizing(border-box);
        @include border-radius(100px);
        &:after,
        &:before {
            display: block;
            position: absolute;
            top: 1px;
            left: 1px;
            bottom: 1px;
            content: "";
        }
        &:before {
            right: 1px;
            background-color: $grayLighter;
            @include border-radius(100px);
            @include transition(all .3s);
            width: 64px;
            @include box-shadow(inset 1px 1px 6px -2px rgba($black, .5));
        }
        &:after {
            width: 24px;
            height: 24px;
            margin-top: 3px;
            margin-left: 3px;
            background-color: $white;
            @include border-radius(100%);
            @include box-shadow(0 2px 5px rgba($black, .3));
            @include transition(all .3s);
        }
    }
    &.center-with-input {
        margin: 6px 0;
    }
    &:checked {
        +label {
            &:before {
                background-color: $success;
            }
            &:after {
                margin-left: 36px;
            }
        }
    }
    &.disabled {
        +label {
            pointer-events: none;
        }
    }
}

.form_input--checkbox:not(:checked),
.form_input--checkbox:checked {
    position: absolute;
    left: -9999px;
    @include opacity(0);
    + label {
        display: inline-block;
        position: relative;
        padding-left: 40px;
        cursor: pointer;
        height: 30px;
        line-height: 30px;
        user-select: none;
        &:before,
        &:after {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 30px;
            height: 30px;
            z-index: 0;
            background: $white;
            border: 1px solid $baseBorderColor;
            @include border-radius(3px);
            text-align: center;
        }
    }
    &.error {
        + label {
            &:before,
            &:after {
                border-color: $error;
                background: lighten($error, 50%);
            }
        }
    }
}

.form_input--checkbox:checked {
    +label {
        &:after {
            @extend .material-icons;
            content: 'done';
            color: $success;
            font-size: 28px;
        }
    }
}

.form_input--checkbox:disabled:not(:checked),
.form_input--checkbox:disabled::checked {
    + label {
        &:before,
        &:after {
            background-color: $grayLighter;
        }
    }
}

.form_input--radio:not(:checked),
.form_input--radio:checked {
    position: absolute;
    left: -9999px;
    @include opacity(0);
    + label {
        display: block;
        position: relative;
        margin-bottom: 10px;
        padding-left: 40px;
        cursor: pointer;
        height: 30px;
        line-height: 30px;
        user-select: none;
        &:before,
        &:after {
            content: '';
            position: absolute;
            left: 0;
            top: 0;
            width: 30px;
            height: 30px;
            z-index: 0;
            background: $white;
            border: 1px solid $baseBorderColor;
            @include border-radius(50px);
            text-align: center;
        }
    }
    &.error {
        + label {
            &:before,
            &:after {
                border-color: $error;
                background: lighten($error, 50%);
            }
        }
    }
}

.form_input--radio:checked {
    +label {
        &:after {
            @extend .material-icons;
            content: 'done';
            color: $success;
            font-size: 28px;
        }
    }
}

.form_input--radio:disabled:not(:checked),
.form_input--radio:disabled::checked {
    + label {
        &:before,
        &:after {
            background-color: $grayLighter;
        }
    }
}

a.btn,
input[type="submit"],
button {
    &:disabled,
    &.disabled {
        //pointer-events: none;
    }
}


select{
    //-webkit-appearance: none; 
}
