$base: '.master-card-row-flex';
#{$base} {
    @include flexbox();
    @include flex-direction(row);
    @include justify-content(space-between);
    font-size: 1em;
    #{$base}-column {
        @include flexbox();
        @include flex-direction(column);
        @include flex(1);
        position: relative;
        margin-left: 1.25em;
        background: none;
        background-size: cover;
        background-color: $brandColorLightCustom;
        padding: 2em;
        color: $grayDark;
        text-align: center;

        #{$base}-column-end {
            @include flex-grow(1);
            margin-bottom: 1em;
        }
        #{$base}-column-header {
            color: $grayDark;
            font-size: 2em;
            margin-top: 0.75em;
            font-weight: $eFontWeightBold;
            text-transform: uppercase;
        }
        #{$base}-column-callout {
            font-weight: $eFontWeightBold;
            font-size: 1.5em;
            color: $brandColorSecondary;
            font-family: $headingsFontFamily;
        }
        ul {
            text-align: left;
            padding: 0 0.5em;
        }
        #{$base}-column-button {
            position: relative;
            background: $success;
            border-bottom: 0;
            color: $white;
            display: block;
            margin: 0 -1.5em -1.5em;
            @include border-radius(0);
            padding: 1.5em;
            font-size: 1.3em;
            font-weight: bold;
            width: 100%;
            &:hover,
            &:active,
            &:focus {
                background: darken($success, 15%);
                border-bottom: 0;
            }

            img {
                width: 40px;
                height: 40px;
                display: inline-block;
                margin-right: 10px;
            }
        }
    }
    img#{$base}-corner-graphics {
        position: absolute;
        width: 75px;
        height: 75px;
        top: -6px;
        right: -6px;
    }
    @media (max-width: 767px) {
        display: block;
        #{$base}-column {
            display: block;
            margin: 0 0 1em 0;
        }
    }
}