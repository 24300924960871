$base: ".master-account-new";
#{$base} {
    position: relative;
    #{$base}-header {
        position: absolute;
        width: 100%;
        height: 280px;
        @media (max-width: 767px) {
            height: 200px;
        }
        top: 0;
        left: 0;
        background: $brandColor;
        z-index: 1;
    }
    #{$base}-content {
        [class*="span"] {
            position: relative;
            z-index: 2;
            h1 {
                font-size: 1.5em;
                font-weight: $eFontWeightNormal;
            }
        }
        #{$base}-heading {
            position: relative;
            margin: 2.5em 0;
            @include box-sizing(border-box);
            padding-bottom: 4em;
            @media (max-width: 767px) {
                padding-bottom: 2em;
            }
            img {
                width: 60%;
                height: auto;
                margin-bottom: 2.5em;
                @media (max-width: 767px) {
                    margin: 0 auto 1em auto;
                    display: table;
                }
            }
            h1 {
                margin: 0;
                color: $white;
                font-size: 2.5em;
                @media (max-width: 767px) {
                    font-size: 2em;
                    text-align: center;
                }
            }
        }
    }
    #{$base}-basic-information {
        .control-group label {
            padding-top: 10px;
            text-align: left;
            @media (max-width: 979px) {
                display: block;
                width: 100%;
                font-size: 0.9em;
            }
        }
        input {
            @include box-sizing(border-box);
            @include border-radius(0);
            @include box-shadow(none);
            padding: 0 15px;
            border: 3px solid $grayLighter;
            outline: 3px solid transparent;
            @include transition(all 0.25s);
            outline-offset: -6px;
            min-height: 2.75em;
            &:active,
            &:focus {
                outline: 3px solid $success;
                border: 3px solid transparent;
                outline-offset: -3px;
                @include box-shadow(none);
            }
            &.input-xlarge {
                width: 100%;
            }
        }
        .control-group.error {
            input {
                &:active,
                &:focus {
                    outline: 3px solid $error;
                    @include box-shadow(none);
                }
            }
        }
    }
    #{$base}-advantages {
        margin-top: 2.5em;
        padding: 1.5em;
        background: desaturate($brandColor, 10%);
        border: 1px solid $white;
        @include box-sizing(border-box);
        color: $white;
        font-size: 0.846em;
        h2,
        h5 {
            color: $white;
        }
        h2 {
            font-family: $masterSecondaryFontFamily;
            font-weight: $eFontWeightBold;
            margin-bottom: 0.75em;
        }
        h5 {
            font-size: 1.3em;
        }
        p {
            color: $grayLight;
            line-height: $baseLineHeight - 5;
            font-size: 0.95em;
        }
        ul {
            list-style-type: none;
            li {
                position: relative;
                margin-top: 2.5em;
                &:before {
                    @extend .material-icons;
                    position: absolute;
                    content: "done";
                    color: $success;
                    width: 15px;
                    height: 15px;
                    top: 0;
                    left: -25px;
                }
            }
        }
    }
    button.btn {
        background: $success;
        border-bottom: 0.3em solid darken($success, 5%);
        color: $white;
        @include border-radius(50px);
        padding: 0.5em 1.5em 0.25em 0.75em;
        i {
            font-size: 2.25em;
            vertical-align: -0.325em;
            margin-right: 0.25em;
        }
        &:hover,
        &:focus,
        a:active {
            @include transition(all, 0.5s ease-out);
            background: darken($success, 5%);
            border-bottom: 0.3em solid darken($success, 10%);
            color: $white;
            i {
                @include transition(all, 0.5s ease-out);
                -ms-transform: rotate(360deg);
                -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
                color: $white;
            }
        }
    }
    // MODIFIERS

.master-account-new-licenseplate-lookup-container {
    background: $grayLighter;
    border: 2px solid $baseBorderColor;
    padding: 1em 1em .5em 1em;
    margin-bottom: 0.5em;
    .module-licenseplate-lookup {
        display: table;
        margin: 0 auto;
    }
}


    fieldset.module-workshop-lookup .module-workshop-lookup-button {
        @media (min-width: 980px) {
            font-size: $baseFontSize - 4;
            width: 33%;
            top: 2em;
            min-height: 4.75em;
            line-height: 4.75em;
        }
        @media (min-width: 768px) and (max-width: 979px) {
            font-size: $baseFontSize - 6;
            width: 33%;
            top: 2.35em;
            min-height: 5.75em;
            line-height: 5.75em;
        }
    }
}
