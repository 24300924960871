/* Espresso navigation > utilities-top */

$eNavUtilitiesTopFontSize: 0.75em;
$eNavUtilitiesTopBackgroundColor: lighten($grayLight, 15%);
$eNavUtilitiesTopLinkColor: darken($grayLight, 25%);
.e-nav-utilities-top {
    font-size: $eNavUtilitiesTopFontSize;
    margin: 0;
    background-color: $eNavUtilitiesTopBackgroundColor;
    display: inline-block;
    float: right;
    z-index: 3;
    position: relative;
    @include border-radius(0 0 0 50px);
    border-width: 0;
    .nav.nav-pills {
        margin-right: 1.25em;
        @media (max-width: 979px) {
            margin-right: 1px;
        }
    }
    .container {
        width: 100%;
        margin-left: auto;
        margin-right: 0;
        padding: 0 1.5em;
        box-sizing: border-box;
    }
}

.e-nav-utilities-top .nav > li > a,
.e-nav-utilities-top .nav > li.dropdown > a.dropdown-toggle,
.e-nav-utilities-top .nav > li.dropdown.open > a.dropdown-toggle {
    color: $eNavUtilitiesTopLinkColor;
    margin: 0;
    padding: 0.5em 1em;
    background: none;
    @include border-radius(0);
    &:hover {
        color: $white;
    }
}

.e-nav-utilities-top .nav > li {
    &.nav-utilities-top-user {
        a {
            color: $grayDark;
            font-weight: $eFontWeightBold;
        }
    }
}

// MOBILE
.master-utilities-top-mobile {
    background: $eNavUtilitiesTopBackgroundColor;
    border-bottom: 1px solid $grayLight;
    ul {
        float: left;
        margin: 0;
        padding: 2px 10px;
        list-style-type: none;
        @include box-sizing(border-box);
        width: 100%;
    }
    li {
        font-size: 0.846em;
        float: left;
        margin-bottom: 0;
        a {
            color: $eNavUtilitiesTopLinkColor;
        }
    }
    li.nav-utilities-top-user {
        font-weight: $eFontWeightBold;
    }
    .pull-right {
        li {
            margin-left: 15px;
        }
    }
}
