$base: ".master-user-info-and-search";
#{$base} {
    background: lighten($grayLight, 15%);
    min-height: 51px;
    color: $white;
    font-size: $baseFontSize - 5;
    font-family: $defaultFontFamily;
    border-top: 3px solid $brandColorSecondary;
    .e-wrapper {
        margin-top: 0;
    }
    [class*="span"] {
        margin-bottom: 0;
    }
    // MOBILE
    @media (max-width: 979px) {
        .e-wrapper {
            width: 100%;
        }
        .span8 {
            width: 100%;
            margin-left: 0;
            padding-right: 20px;
        }
    }
    #{$base}-actionlist {
        margin: 0;
        list-style-type: none;
        float: right;
        >li {
            float: left;
            line-height: 51px;
            margin: 0 0 0 20px;
            a#{$base}-link-createprofile {
                display: block;
                @include border-radius(0);
                background: $success;
                padding: 0 15px;
                color: $white;
                font-family: $headingsFontFamily;
                font-weight: $eFontWeightBold;
                font-size: $baseFontSize - 4;
                height: 37px;
                line-height: 37px;
                margin-top: 7px;
                @include box-sizing(border-box);
                &:hover,
                &:focus,
                &:active {
                    @include transition(background, 0.15s);
                    text-decoration: none;
                    background: darken($success, 10%);
                }
            }
            a#{$base}-link-myprofile {
                color: $grayDark;
                font-weight: $eFontWeightBold;
                &:hover,
                &:focus {
                    text-decoration: none;
                }
            }
        }

    }
}

// INLINE CART
#{$base}-webshop-inline-cart {
    position: relative;
    float: right;
    margin-left: 20px;
    height: 37px;
    margin-top: 7px;
    color: $white;
    @include border-radius(2px);
    &-ordernumber,
    &-orderbutton {
        height: 37px;
        line-height: 37px;
        float: left;
        font-size: $baseFontSize - 1;
        text-align: center;
        @include transition(background, 0.15s);
    }
    @at-root #{&}-ordernumber {
        background: $brandColorLight;
        font-family: $masterSecondaryFontFamily;
        font-weight: $eFontWeightBold;
        width: 42px;
    }
    @at-root #{&}-orderbutton {
        position: relative;
        background: lighten($brandColor, 10%);
        width: 75px;

        small {
            padding-left: 20px;
        }

        &:before {
            @extend .material-icons;
            content: "shopping_cart";
            position: absolute;
            width: 25px;
            height: 25px;
            top: 10px;
            left: 7px;
        }

    }
}

a#{$base}-webshop-inline-cart {
    color: $white;
    &:hover,
    &:focus {
        #{$base}_webshopInlineCart-orderbutton {
            background: lighten($brandColor, 15%);
            @include transition(background, 0.15s);
        }
        #{$base}_webshopInlineCart-ordernumber {
            background: lighten($brandColor, 10%);
            @include transition(background, 0.15s);
        }
    }
}

// INLINE BOOKING CART

a#{$base}-webshop-inline-bookingcart {
    position: relative;
    float: right;
    margin-left: 20px;
    height: 37px;
    font-size: $baseFontSize - 4;
    font-weight: $eFontWeightBold;
    line-height: 37px;
    margin-top: 7px;
    color: $white;
    padding: 0 10px;
    @include border-radius(2px);
    font-family: $headingsFontFamily;
    background: $info;

    i.calendar {
        font-size: 2em;
        line-height: 1;
        vertical-align: -8px;
        margin-right: 9px;
    }

    i.heads-up {
        position: absolute;
        top: 6px;
        left: 23px;
        @include animate('zoomPulse 1s infinite ease-in-out');
        background: rgba($error, 0.7);
        @include border-radius(50px);
        padding: 1px;
    }

    &:focus, &:active, &:hover {
        @include transition(all, 0.15s);
        color: $white;
        text-decoration: none;
        background: darken($info, 10%);
    }

}


// GLOBAL HEADER SEARCH
#{$base}-global-search {
    form {
        margin: 0;
    }
    .e-search-form {
        margin-top: 0;
        width: 100% !important;
        .e-search-box {
            @include border-radius(0);
            background: $white;
            height: 37px;
            top: 7px;
            margin-bottom: 0;
        }
        input[type="text"] {
            @include border-radius(0);
            border: none;
            color: $gray;
            font-size: $baseFontSize - 2;
            padding-left: 1em;
            background: $white;

            @media (max-width: 979px) {
                background: $brandColorLightCustom;
            }
        }
        button {
            color: $gray;
            @include border-radius(0);
            border: none;
            @include box-shadow(none);
            background: transparent;
            height: 37px;
            line-height: 37px;
            font-size: $baseFontSize + 6;
            margin: 0;
            padding: 0 3%;
            outline: none;
        }
    }
}

#{$base}-workshop-local {
    position: relative;
    padding-left: 25px;
    color: $grayDark;
    &:before {
        @extend .material-icons;
        position: absolute;
        top: 14px;
        left: 0;
        font-size: 2em;
        content: 'location_on';
        color: $brandColorSecondary;
    }
}

// TABLET
@media (min-width: 768px) and (max-width: 979px) {
    .e-header {
        padding-bottom: 0;
    }
    .e-header .span10 {
        width: 100%;
        position: relative;
        padding-top: 1em;
        margin-top: 0;
        margin-left: 0;
        margin-bottom: 0 !important;
    }
    .e-header.affix .span10 {
        padding-top: 0.5em;
    }
}

#{$base}-tablet-header {
    #{$base}-mobile-logo {
        position: relative;
        img {
            height: 43px;
            margin-top: -2px;
        }
    }
    .span2 {
        margin-top: 2px;
        .btn-navbar {
            float: right;
            background: none;
            box-shadow: none;
            border: none;
            padding: 0;
            margin: 0;
            font-size: 2em;
            margin-right: -5px;
            color: $grayDark;
            text-shadow: none;
            &:hover,
            &:focus,
            &:active {
                background: none;
                outline: none;
                color: $brandColorSecondary;
            }
            span {
                font-size: 0.3em;
                vertical-align: 0.3em;
                text-transform: uppercase;
            }
        }
    }
    #{$base}-global-search .e-search-form {
        margin-top: 0;
        .e-search-box {
            top: 2px;
            background: $brandColorLight;
        }
    }
}

// MOBILE
#{$base}-mobile-header {
    #{$base}-mobile-logo {
        width: 50%;
        float: left;
        @include box-sizing(border-box);
        padding-left: 10px;
        margin-bottom: 10px;
        img {
            height: 36px;
            margin-top: 10px;
        }
    }
    #{$base}-mobile-basket {
        width: 50%;
        float: left;
        @include box-sizing(border-box);
        padding-right: 10px;
        margin-bottom: 10px;
        #{$base}-webshop-inline-cart, #{$base}-webshop-inline-bookingcart {
            margin-top: 10px;
        }

    }
    #{$base}-mobile-wrapper {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        height: 37px;
        @include box-sizing(border-box);
        background: #ccc;
        > div#{$base}-global-search {
            flex: 1;
            .e-search-form .e-search-box {
                top: 0;
                background: $brandColorLight;
                input {
                    padding-left: 10px;
                }
            }
        }
        #{$base}-menu-button {
            position: relative;
            width: 50px;
            height: 37px;
            background: $brandColorLight;
            button {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                padding: 0 0 0 2px;
                width: 100%;
                border: none;
                background: transparent;
                text-align: center;
                @include box-sizing(border-box);
                outline: none;
                i {
                    font-size: 37px;
                    color: $white;
                }
            }
        }
        a#{$base}-carinfo-button {
            position: relative;
            width: 49px;
            height: 37px;
            background: $success;
            padding-top: 4px;
            @include box-sizing(border-box);
            &:after {
                top: 100%;
                left: 50%;
                border: solid transparent;
                content: " ";
                height: 0;
                width: 0;
                position: absolute;
                pointer-events: none;
                border-top-color: $success;
                border-width: 10px;
                margin-left: -10px;
            }
            img {
                width: 30px;
                display: table;
                margin: 2px auto;
            }
        }
    }
    #{$base}-mobile-carinfo {
        background: $gray;
        a,
        ul,
        li,
        p,
        small {
            color: $white;
        }
        >ul {
            position: relative;
            margin: 0;
            padding: 10px 10px 15px 10px;
            list-style-type: none;
            img {
                position: absolute;
            }
            li {
                span {
                    padding-left: 40px;
                    font-size: 0.846em;
                }
            }
            li.user-info-license-list-item {
                text-transform: uppercase;
            }
            li:first-child img {
                left: 5px;
            }
            li:last-child {
                text-align: right;
                margin-bottom: 0;
                a {
                    background: $success;
                    padding: 7px 15px;
                    @include border-radius(50px);
                    font-family: $headingsFontFamily;
                    font-weight: $eFontWeightBold;
                }
            }
        }
    }
}

#{$base}-mobile-carinfo-actions {
    >ul {
        @include flexbox();
        @include justify-content(space-around);
        list-style: none;
        padding: 0;
        margin: 0;
        width: 100%;
        border-top: 1px solid $grayDark;
        li {
            @include flex(1 auto);
            @include align-self(stretch);
            text-align: center;
            margin: 0;
            a {
                font-size: .8em;
                text-decoration: none;
                border-left: 1px solid $grayDark;
                padding: 1em;
                display: block;
                font-weight: $eFontWeightBold;
                @include transition(all .3s ease-in-out);
                &:hover {
                    background: $grayDark;
                }
                &.master-user-info-and-search-link-createprofile,
                &.master-user-info-and-search-link-myprofile {
                    background: $success;
                }
                &.master-user-info-and-search-workshop-lookup--undefined {
                    background: $error;
                }
                .material-icons {
                    line-height: 0;
                }
            }
            &:first-child {
                a {
                    border-left: 0;
                }
            }
        }
    }
}


.js-master-user-info-and-search-actionlist-carInfo {
    color: $grayDark;
}