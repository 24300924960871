@import "colorscheme";
@import "global";
@import "e-header";
@import "e-footer";
@import "e-nav-utilities-top";
@import "e-nav-global-top";
@import "header-user-info-and-search";
@import "frontpage-specific-usp-area";
@import "cards";
@import "master-modal-user-login";
@import "webshop-and-service-details";
@import "webshop-item-grid-standard";
@import "webshop-add-to-cart";
@import "webshop-productlist-topleft-menu-and-filters";
@import "header-workshop-lookup";
@import "custom-buttons";
@import "service-bookservice";
@import "service-bookservice-receipt";
@import "service-bookservice-stepguide";
@import "service-serviceoverview-list";
@import "workshop-validation";
@import "inputs";
@import "webshop-customer-center";
@import "cookie-message";
.master-service-bookservice .master-service-bookservice-section ul.master-service-bookservice-servicelist li.featured-item {
    outline: none;
    box-shadow: 0px 0px 0px 1px $success, 3px 3px 3px rgba(0, 0, 0, 0.3);
}

.master-service-bookservice .master-service-bookservice-section ul.master-service-bookservice-servicelist li input[type=checkbox]+label span {
    width: 46px;
}

ul.checklist {
    &.no-checks {
        text-align: center;
        li {
            margin-left: 0;
        }
        li:before {
            content: '';
        }
    }
}
@media (max-width: 767px) {
  .master-button--campaign, .master-button--large, .master-button--mega {
    float: left;
  }
}
.master-service-bookservice-section.section--large {
  margin-top: 20px;
}

.mb-0 {
  margin-bottom: 0;
}

.mt-0 {
  margin-top: 0 !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.d-flex {
  display: flex;
}

.align-items-center {
  align-items: center;
}

.big-checkbox {
  height: 24px !important;
  width: 24px !important;
}

.fs-0 {
  font-size: 14px;
}

.accordion-section {
  box-sizing: border-box;
  padding: 0.35em 1em;
  width: 100%;

  @media (min-width: 768px) {
    width: 50%;
  }
}

// COOKIEBOT POPUP FIX - ALTERNATIVE FIX, UPGRADE TO NEWER BOOTSTRAP
#cookiebanner {
  @media (min-width:576px) {
     .flex-sm-column {
      flex-direction: column !important
    }
    .mt-sm-3, .my-sm-3 {
      margin-top: 2.25em!important;
    }
    .mb-sm-0{
      margin-bottom:0!important;
    }
  }
  
  @media (min-width:768px) {
    .flex-sm-column {
      flex-direction: column !important
    }
  }
  
  @media (min-width:1024px) {
    .flex-md-row {
      flex-direction: row !important;
    }
  }
  
  .CybotCookiebotDialogBodyLevelButtons {
    padding-left: 2%;
  }
  
  .flex-column-reverse {
    flex-direction: column-reverse !important;
  }
  
  .mt-2, .my-2 {
    margin-top: 1.5em !important;
  }
  
  .mt-lg-0, .mx-0 {
    margin-top: 0!important;
  }
  
  .ml-lg-0, .mx-0 {
    margin-left: 0!important;
  }
  
  .mb-1{
    margin-bottom:.25rem!important
  }
  
  .ml-0{
    margin-left:0!important
  }

  input:checked+.slider {
    background-color: $brandColorSecondary !important;
  }

  .btn-primary {
    background-color: $brandColorSecondary !important;

    &:hover {
      background-color: darken($brandColorSecondary, 10%) !important;
    }
  }
}

.modal {
  &.fade {
    &.in {
      visibility: visible;
    }
  }
}