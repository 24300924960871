$base: ".master-service-bookservice";
$gold: linear-gradient(135deg, #cb9b50 0%, #f7e9c6 50%, #e8d6a0 100%);
$silver: linear-gradient(135deg, #e0e0e0 0%, #fefefe 50%, #f2f2f2 100%);
$bronze: linear-gradient(135deg, #d8ac9d 0%, #eacaba 50%, #e2bdad 100%);
#{$base} {
    .section--receipt {
        position: relative;
        background: $white;
        padding: 20px;
        border: none;
        box-shadow: 5px 5px 0px rgba(0, 0, 0, 0.1);
        @media (max-width: 767px) {
            padding: 10px;
        }
        #{$base}-workshop-info {
            h2 {
                font-weight: $eFontWeightLight;
                font-size: $baseFontSize + 4;
                margin-bottom: 0;
            }
            h3 {
                font-weight: $eFontWeightBold;
                font-size: $baseFontSize - 4;
                color: $success;
                margin-top: 5px;
                margin-bottom: 0;
            }
            p {
                font-size: $baseFontSize - 4;
                line-height: $baseLineHeight - 6;
            }
        }
        #{$base}-drop-date {
            img {
                display: block;
                max-width: 60%;
            }
            h5 {
                margin-bottom: 0;
            }
            p {
                font-weight: $eFontWeightLight;
                font-size: $baseFontSize + 4;
                margin-bottom: 0;
            }
        }
        #{$base}-orderlines-edit-container {
            position: relative;
            h5,
            .btn.btn-small {
                float: left;
            }
            .btn.btn-small {
                margin-left: 15px;
                margin-top: -4px;
            }
        }
        ul#{$base}-orderlines {
            margin: 0 0 25px 0;
            padding: 0;
            list-style-type: none;
            li {
                margin-bottom: 0;
                h3,
                #{$base}-orderlines-price {
                    font-weight: $eFontWeightNormal;
                    font-size: $baseFontSize + 4;
                    margin: 0;
                }
                #{$base}-orderlines-price {
                    font-size: $baseFontSize;
                }
                border-bottom: 1px dotted $baseBorderColor;
                padding: 15px 0;
                h5 {
                    font-style: italic;
                    color: $textColorMuted;
                    margin: 5px 0 0 0;
                }
                p {
                    font-size: $baseFontSize - 3;
                    line-height: $baseLineHeight - 7;
                    margin: 0;
                }
                .master-service-checkout-orderline-campaign {
                    display: inline-block;
                    padding: .3em 1em 0em 1em;
                    @include border-radius(3px);
                    border-bottom: 0.3em solid darken($grayDark, 5%);
                    font-size: $baseFontSize - 6;
                    font-weight: $eFontWeightBold;
                    font-family: $masterSecondaryFontFamily;
                    font-style: normal;
                    text-transform: uppercase;
                    background: $grayDark;
                    color: $white;
                    margin: 0 0 0 2em;
                    line-height: 18px;
                    vertical-align: middle;
                    @media (max-width: 767px) {
                        margin: .5em auto .3em auto;
                        display: table;
                        + p {
                            text-align: center;
                        }
                    }
                }
                .master-service-checkout-orderline-servicepack {
                    display: inline-block;
                    padding: .3em 1em 0em 1em;
                    @include border-radius(3px);
                    border-bottom: 0.3em solid darken($grayDark, 5%);
                    font-size: $baseFontSize - 6;
                    font-weight: $eFontWeightBold;
                    font-family: $masterSecondaryFontFamily;
                    font-style: normal;
                    text-transform: uppercase;
                    color: $textColor;
                    margin: 0 0 0 2em;
                    line-height: 18px;
                    vertical-align: middle;
                    @media (max-width: 767px) {
                        margin: .5em auto .3em auto;
                        display: table;
                        + p {
                            text-align: center;
                        }
                    }
                    &.master-service-checkout-orderline-servicepack-Bronze {
                        background: $bronze;
                        border-bottom-color: darken(#d8ac9d, 5%);
                    }
                    &.master-service-checkout-orderline-servicepack-Silver {
                        background: $silver;
                        border-bottom-color: darken(#e0e0e0, 5%);
                    }
                    &.master-service-checkout-orderline-servicepack-Gold {
                        background: $gold;
                        border-bottom-color: darken(#cb9b50, 5%);
                    }
                }
            }
            li#{$base}-orderlines-total {
                border-bottom: 4px double $baseBorderColor;
                h3,
                #{$base}-orderlines-price {
                    font-weight: $eFontWeightBold;
                    font-size: $baseFontSize + 4;
                }
            }
        }
        ul#{$base}-orderlines-further-info {
            list-style-type: none;
            margin: 0;
            padding: 0;
            li {
                position: relative;
                padding-left: 2.5em;
                font-size: $baseFontSize - 2;
                &:before {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 1em;
                    @extend .material-icons;
                    content: "done";
                    font-size: 1.5em;
                    color: $success;
                }
            }
        }
    }
    #{$base}-personal-info {
        h5 {
            margin: 0;
        }
        p {
            font-size: $baseFontSize + 2;
            font-weight: $eFontWeightLight;
            overflow: hidden;
            text-overflow: ellipsis;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
    #{$base}-car-info-wrapper {
        margin: 25px 0 0 0;
        h5:not(:first-child) {
            margin: 0;
        }
        p {
            font-size: $baseFontSize - 2;
        }
    }
    #{$base}-textarea-append {
        margin: 0 0 50px 0;
        &:after {
            display: none;
        }
    }
    #{$base}-disclaimer-append {
        padding: 0 0 0 55px;
        border: none;
        &:after {
            top: 0;
            left: 0;
        }
    }
    .btn.btn-small {
        padding: .35em 2em .175em;
    }
}
